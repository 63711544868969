import {
  InputGroupWrapper,
  Section,
  SectionTitle
} from '../../add-new-product-single/add-new-product-single-styled';
import { InputGroup } from '../../../../../components/input-group/input-group';
import { GlobalInput } from '../../../../../components/global-input/global-input';
import { GlobalSelect } from '../../../../../components/global-select/global-select';
import { TagsWrapper } from '../business-information-styled';
import { Tag } from '../../add-new-product-single/components/tag/tag';
import { GlobalTextarea } from '../../../../../components/global-textarea/global-textarea';
import { useDispatch, useSelector } from 'react-redux';
import { addCategory, removeCategory, updatename, updateDescription } from '../../../../../redux/slices/businessSettingsSlice';
import { useState } from 'react';


export const BusinessInformationSection = () => {

  const dispatch = useDispatch();


  const { name, description, categories, allCategories } = useSelector(
    (state) => state.businessSettingsReducer
  );

  const [error, setError] = useState({
    name: '',
    description: '',
    category: '',
  });

  const handleSelectCategory = (selectedOption) => {
    const category = { id: selectedOption.value, name: selectedOption.label };

    if (!categories.some(cat => cat.id === category.id)) {
      dispatch(addCategory(category));
    }
  };

  const handleRemoveCategory = (category) => {
    dispatch(removeCategory(category));
  };
  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "name") {
      dispatch(updatename(value));
    }
    else if (name === "description") {
      dispatch(updateDescription(value));
    }
  }


  return (
    <Section>
      <SectionTitle>Business Information</SectionTitle>
      <InputGroupWrapper>
        <InputGroup label={'name*'}>
          <GlobalInput placeholder={'Enter your business name'} onChange={handleChange} value={name} name="name" required />
          {error.name && <span className="error-message">{error.name}</span>}

        </InputGroup>
      </InputGroupWrapper>
      <InputGroupWrapper>
        <InputGroup label={'categories*'}>
          <GlobalSelect
            options={allCategories.map((category) => ({
              value: category.id,
              label: category.name,
            }))}
            onChange={handleSelectCategory}
            value={categories.map(cat => ({
              value: cat.id,
              label: cat.name,
            }))}
          />
        </InputGroup>
      </InputGroupWrapper>
      <InputGroupWrapper>
        <TagsWrapper>
          {categories.map((cat) => (
            <Tag key={cat.id} onClick={() => handleRemoveCategory(cat)}>
              {cat.name}
            </Tag>
          ))}
        </TagsWrapper>
      </InputGroupWrapper>
      <InputGroupWrapper>
        {/* TODO {Future release} use AI to help vendor generate business description */}
        <InputGroup label={'description*'}>
          <GlobalTextarea placeholder={'Give a short description of your business'} name="description" onChange={handleChange} value={description} />
        </InputGroup>
      </InputGroupWrapper>
    </Section>
  );
};
