import { CloseBtn, TagStyled } from './tag-styled';

export const Tag = ({ children,onClick }) => {
  return (
    <TagStyled>
      {children}
      <CloseBtn onClick={onClick}>
        <svg
          width='16'
          height='16'
          viewBox='0 0 16 16'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M11.3346 4.66699L4.66797 11.3337M4.66797 4.66699L11.3346 11.3337'
            stroke='#ACADB9'
            strokeWidth='1.6'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </CloseBtn>
    </TagStyled>
  );
};
