import { Breadcrumbs } from 'components/breadcrumbs/breadcrumbs';
import { GlobalButton } from 'components/global-button/global-button';
import { GlobalInputSelect } from 'components/global-input-select/global-input-select';
import { GlobalInput } from 'components/global-input/global-input';
import { GlobalSelect } from 'components/global-select/global-select';
import { InputGroup } from 'components/input-group/input-group';
import { GlobalInputPhone } from 'components/global-input-phone/global-input-phone';
import { SidebarSubtitle } from '../all-orders/all-order-styled';
import { SidebarTitle } from '../all-customers/all-customers-styled';
import { BottomTotal } from '../all-customers/all-customers-styled';
import { TotalAmount } from '../all-customers/all-customers-styled';
import { Button } from './table-styled';
import {
  InputGroupWrapper,
  Section,
  SectionSubtitle,
  SectionTitle
} from '../add-new-product-single/add-new-product-single-styled';
import { Title } from '../add-new-product/add-new-product-styled';
import { ButtonsWrapper, Container, Main, Side, Wrapper } from './create-new-order-styled';

import { useState } from 'react';
import { SidebarBackground } from '../business-information/business-information-styled';
import { CreateNewOderSidebar } from './components/create-new-oder-sidebar';
import { ReactComponent as PlusIcon } from './icons/plus.svg';
import { ReactComponent as SaveIcon } from './icons/save.svg';
import { ReactComponent as XSquareIcon } from './icons/x-square.svg';
import { useEffect } from 'react';
import axios from 'axios';
import { TableWrapper, Styles } from './table-styled';
import { getProducts } from '../../../../redux/slices/products/productSlice';
import { useDispatch } from 'react-redux';
import ColourRingLoader from 'components/loaders/colour-ring-loader/ColourRingLoader';
import { set } from 'lodash';
import { GlobalTextarea } from 'components/global-textarea/global-textarea';

const breadcrumbs = [
  { link: '#', name: 'Sell Items' },
  { link: '#', name: 'Orders' },
  { link: '#', name: 'Create New Order' }
];

const selectOptions = [
  { label: 'test', value: 'test' },
  { label: 'test1', value: 'test1' },
  { label: 'test2', value: 'test2' }
];


export const CreateNewOrder = () => {
  const [isSidebarHidden, setIsSidebarHidden] = useState(true);

  const [searchQuery, setSearchQuery] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [orderData, setOrderData] = useState(null);
  const [successMessage, setSuccessMessage] = useState(false);
  const [successDraftMessage, setSuccessDraftMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [draftLoading, setDraftLoading] = useState(false);
  const [instructions, setInstructions] = useState('');


  const paymentMethod = [
    { value: 'cash-delivery', label: 'Cash on delivery' },
    { value: 'mukuru', label: 'Mukuru' },
    { value: 'ecocash', label: 'Ecocash' },
    { value: 'inn-bucks', label: 'InnBucks' },
    { value: 'visa-mastercard', label: 'Visa/Mastercard' },
    { value: 'contact-vendor', label: 'Contact Vendor' },


  ];
  const deliveryMethod = [
    { value: 'everything-zimbabwean', label: 'Everything Zimbabwean' },
    { value: 'collection', label: 'Shop collection' },
    { value: 'contact-vendor', label: 'Contact Vendor' },

  ];

  const statusOptions = [
    { value: 'pending', label: 'Pending payment' },
    { value: 'on-hold', label: 'Awaiting payment' },
    { value: 'processing', label: 'Processing' },
    { value: 'completed', label: 'Completed' },
    { value: 'cancelled', label: 'Cancelled' },
  ]
  const [customer, setCustomer] = useState({
    firstName: '',
    lastName: '',
    email: '',
    whatsapp: '',
    phone: ''
  });
  const [billingAddress, setBillingAddress] = useState({
    street: '',
    city: '',
    suburb: '',
    country: '',
    province: '',
  });

  const calculateTotalAmount = () => {

    return tableData.reduce((total, item) => {
      const itemsTotal = parseFloat(item.total);
      const totalAmount = total + itemsTotal;
      return totalAmount;
    }, 0)


  };

  const [order, setOrder] = useState({
    paymentMethod: 'Cash on delivery',
    status: 'Pending payment',

  });


  const [deliveryAddress, setDeliveryAddress] = useState({
    street: '',
    city: '',
    suburb: '',
    country: '',
    province: '',
  });


  const [products, setProducts] = useState([]);

  // Get products from the redux store
  const dispatch = useDispatch();
  const bID = 80;
  useEffect(() => {
    const params = {
      bID: bID
    };
    dispatch(getProducts(params)).then((result) => {

      setProducts(result.payload);
      setFilteredProducts(result.payload);
    });
  }, []);
  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    whatsapp: ''
  });

  const validateForm = () => {
    let formErrors = {};

    if (!customer.firstName) {
      formErrors.firstName = 'First name is required';
    }
    if (!customer.lastName) {
      formErrors.lastName = 'Last name is required';
    }


    if (!customer.email) {

      formErrors.email = 'Email is required';
    }

    if (!customer.phone) {
      formErrors.phone = 'Phone number is required';
    }
    if (tableData.length === 0) {
      formErrors.products = 'Select products';
    }

    setErrors(formErrors);


    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = async () => {
    const APIUrl = process.env.REACT_APP_API_ENDPOINT + process.env.REACT_APP_ORDER_CREATE_API_URL;
    console.log(APIUrl)
    if (validateForm()) {

      const orderDetails = {
        bID: bID,
        customer,
        billingAddress,
        deliveryDetails: {
          method: deliveryMethod,
          address: deliveryAddress,
          instructions
        },
        orderDetails: {
          ...order,
          totalAmount: undefined
        },
        products: tableData
      };
      try {
        setLoading(true);
        const response = await axios.post(APIUrl, orderDetails);
        if (response.data.status === 200) {
          setOrderData(response.data.data);
          setSuccessMessage(true);
          setLoading(false);
        }
      } catch (error) {
        console.error("Failed to create order:", error);
        setLoading(false);

      }
      return orderDetails;
    }

  }
  const handleDraftSubmit = async () => {
    const APIUrl = process.env.REACT_APP_API_ENDPOINT + process.env.REACT_APP_ORDER_CREATE_API_URL;

    setDraftLoading(true);
    if (validateForm()) {

      const draftOrder = { ...order, status: 'Draft' }
      const orderDetails = {
        bID: bID,
        customer,
        billingAddress,
        deliveryDetails: {
          method: deliveryMethod,
          address: deliveryAddress,
          instructions
        },
        orderDetails: {
          ...draftOrder,
          totalAmount: undefined
        },
        products: tableData
      };
      try {
        const response = await axios.post(APIUrl, orderDetails);
        if (response.data.status === 200) {

          setOrderData(response.data.data);
          setSuccessDraftMessage(true);
          setDraftLoading(false);
        }
      } catch (error) {
        console.error("Failed to create order:", error);
        setDraftLoading
      }

      return orderDetails;
    }
  }
  useEffect(() => {
    const totalAmount = calculateTotalAmount();
    setOrder((prevOrder) => ({
      ...prevOrder,
      totalAmount: totalAmount,
    }));
  }, [tableData]);


  const handleSearchChange = (inputValue) => {
    if (inputValue) {
      const filtered = products.filter((product) =>
        product.title.toLowerCase().includes(inputValue.toLowerCase())
      );
      setFilteredProducts(filtered);
    } else {
      setFilteredProducts(products);
    }
  };


  const handleProductSelect = (selectedOption) => {
    const product = products.find(p => p.id === selectedOption.value);

    const isProductInTable = tableData.some(item => item.id === product.id);

    if (!isProductInTable) {
      const usdPrice = product.price.normalPrice.find(p => p.currency === 'USD').amount;

      setTableData([
        ...tableData,
        {
          id: product.id,
          productName: product.title,
          quantity: 1,
          price: usdPrice,
          total: usdPrice,
          thumbnail: product.image.url
        },
      ]);
      // console.log(tableData)
    }

    const updatedProducts = filteredProducts.filter((prod) => prod.id !== product.id);
    setFilteredProducts(updatedProducts);

    setSearchQuery('');
  };

  const updateQuantity = (productId, newquantity) => {
    const updatedTableData = tableData.map(item => {
      if (item.id === productId) {
        const updatedquantity = Math.max(1, parseInt(newquantity, 10));  // Ensure quantity is always >= 1
        const updatedTotal = item.price * updatedquantity;
        return { ...item, quantity: updatedquantity, total: updatedTotal };  // Update item
      }
      return item;
    });
    setTableData(updatedTableData);
  };


  const removeProductFromOrder = (productId) => {

    const removedProduct = tableData.find(item => item.id === productId);

    if (removedProduct) {

      const updatedTableData = tableData.filter(item => item.id !== productId);
      setTableData(updatedTableData);

      setFilteredProducts((prevProducts) => [
        ...prevProducts,
        {
          id: removedProduct.id,
          title: removedProduct.productName,
          price: { normalPrice: [{ currency: 'USD', amount: removedProduct.price }] },
          image: { url: removedProduct.thumbnail }
        }
      ]);
    }
  };



  const downloadOrderJSON = (data) => {
    const jsonContent = JSON.stringify(data, null, 2);
    const blob = new Blob([jsonContent], { type: 'application/json' });
    const url = URL.createObjectURL(blob);


    const link = document.createElement('a');
    link.href = url;
    link.download = `order_${data.orderDetails.status}.json`;
    document.body.appendChild(link);
    link.click();


    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };
  // console.log(filteredProducts);

  return (
    <Container>
      <Breadcrumbs links={breadcrumbs} />
      <Title>Create New Order</Title>
      <Wrapper>
        <Main>
          <Section>
            <SectionTitle>Customer Billing Details</SectionTitle>
            <InputGroupWrapper>
              <InputGroup label={'first name'}>
                <GlobalInput
                  placeholder={'Enter first name'}
                  value={customer.firstName}
                  onChange={(e) => setCustomer({ ...customer, firstName: e.target.value })}
                />
                {errors.firstName && <span style={{ color: 'red' }}>{errors.firstName}</span>}
              </InputGroup>

            </InputGroupWrapper>
            <InputGroupWrapper>
              <InputGroup label={'Last name'}>
                <GlobalInput
                  placeholder={'Enter last name'}
                  value={customer.lastName}
                  onChange={(e) => setCustomer({ ...customer, lastName: e.target.value })}
                />
                {errors.lastName && <span style={{ color: 'red' }}>{errors.lastName}</span>}
              </InputGroup>

            </InputGroupWrapper>
            <InputGroupWrapper>
              <InputGroup label={'email'}>
                <GlobalInput
                  placeholder={'Enter your email'}
                  value={customer.email}
                  onChange={(e) => setCustomer({ ...customer, email: e.target.value })}
                />
                {errors.email && <span style={{ color: 'red' }}>{errors.email}</span>}

              </InputGroup>
            </InputGroupWrapper>
            <InputGroupWrapper>
              {/* TODO add country codes */}
              <InputGroup label={'Phone Number'}>
                <GlobalInputPhone placeholder={'Your Phone Number'}
                  value={customer.phone}
                  onChange={(e) => setCustomer({ ...customer, phone: e.target.value })}
                />
                {errors.phone && <span style={{ color: 'red' }}>{errors.phone}</span>}
              </InputGroup>
            </InputGroupWrapper>
            <InputGroupWrapper>
              <InputGroup label={'WhatsApp Number'}>
                <GlobalInputPhone placeholder={'Your WhatsApp Number'}
                  value={customer.whatsapp}
                  onChange={(e) => setCustomer({ ...customer, whatsapp: e.target.value })}
                />

              </InputGroup>
            </InputGroupWrapper>
            <SidebarSubtitle>Billing Address</SidebarSubtitle>
            <InputGroupWrapper>
              <InputGroup label={'street address'}>
                <GlobalInput
                  placeholder={'Enter your street address'}
                  value={billingAddress.street}
                  onChange={(e) => setBillingAddress({ ...billingAddress, street: e.target.value })}
                />
              </InputGroup>
            </InputGroupWrapper>
            <InputGroupWrapper>
              <InputGroup label={'suburb'}>
                <GlobalInput
                  placeholder={'Enter suburb'}
                  value={billingAddress.suburb}
                  onChange={(e) => setBillingAddress({ ...billingAddress, suburb: e.target.value })}
                />
              </InputGroup>
            </InputGroupWrapper>
            <InputGroupWrapper>
              <InputGroup label={'city'}>
                <GlobalInput
                  placeholder={'Enter city'}
                  value={billingAddress.city}
                  onChange={(e) => setBillingAddress({ ...billingAddress, city: e.target.value })}
                />
              </InputGroup>
            </InputGroupWrapper>
            <InputGroupWrapper>
              <InputGroup label={'Province'}>
                <GlobalInput
                  placeholder={'Enter province'}
                  value={billingAddress.province}
                  onChange={(e) => setBillingAddress({ ...billingAddress, province: e.target.value })}
                />
              </InputGroup>
            </InputGroupWrapper>
            <InputGroupWrapper>
              <InputGroup label={'country'}>
                <GlobalInput
                  placeholder={'Enter country'}
                  value={billingAddress.country}
                  onChange={(e) => setBillingAddress({ ...billingAddress, country: e.target.value })}
                />
              </InputGroup>
            </InputGroupWrapper>
          </Section>
          <Section>
            <SectionTitle>Order Details</SectionTitle>

            <InputGroupWrapper>
              <InputGroup label={'order status'}>
                <GlobalSelect
                  options={statusOptions}
                  value={statusOptions.find(option => option.value === order.status)}
                  onChange={(selectedOption) => {
                    setOrder({ ...order, status: selectedOption.label });

                  }}

                />
              </InputGroup>
            </InputGroupWrapper>
            <InputGroupWrapper>
              <InputGroup label={'payment method'}>
                <GlobalSelect options={paymentMethod}
                  value={paymentMethod.find(option => option.value === order.paymentMethod)}
                  onChange={(selectedOption) => {
                    setOrder({ ...order, paymentMethod: selectedOption.label })
                  }}
                />
              </InputGroup>
            </InputGroupWrapper>
            <InputGroupWrapper>
              <InputGroup label={'delivery method'}>
                <GlobalSelect options={deliveryMethod}
                  value={deliveryMethod.find(option => option.value === deliveryMethod)}
                  onChange={(selectedOption) => {
                    setOrder({ ...order, deliveryMethod: selectedOption.label })
                  }}
                />
              </InputGroup>
            </InputGroupWrapper>
            <SidebarSubtitle>Delivery Address</SidebarSubtitle>
            <InputGroupWrapper>
              <InputGroup label={'street address'}>
                <GlobalInput
                  placeholder={'Enter street address'}
                  value={deliveryAddress.street}
                  onChange={(e) => setDeliveryAddress({ ...deliveryAddress, street: e.target.value })}
                />
              </InputGroup>
            </InputGroupWrapper>
            <InputGroupWrapper>
              <InputGroup label={'suburb'}>
                <GlobalInput
                  placeholder={'Enter suburb'}
                  value={deliveryAddress.suburb}
                  onChange={(e) => setDeliveryAddress({ ...deliveryAddress, suburb: e.target.value })}
                />
              </InputGroup>
            </InputGroupWrapper>
            <InputGroupWrapper>
              <InputGroup label={'city'}>
                <GlobalInput
                  placeholder={'Enter city'}
                  value={deliveryAddress.city}
                  onChange={(e) => setDeliveryAddress({ ...deliveryAddress, city: e.target.value })}
                />
              </InputGroup>
            </InputGroupWrapper>
            <InputGroupWrapper>

              <InputGroup label={'province'}>
                <GlobalInput
                  placeholder={'Enter province'}
                  value={deliveryAddress.province}
                  onChange={(e) => setDeliveryAddress({ ...deliveryAddress, province: e.target.value })}
                />
              </InputGroup>
            </InputGroupWrapper>
            <InputGroupWrapper>
              <InputGroup label={'country'}>
                <GlobalInput
                  placeholder={'Enter country'}
                  value={deliveryAddress.country}
                  onChange={(e) => setDeliveryAddress({ ...deliveryAddress, country: e.target.value })}
                />
              </InputGroup>
            </InputGroupWrapper>

          </Section>
          <Section>
            <SidebarTitle>Order Items</SidebarTitle>
            <InputGroupWrapper>
              <InputGroup label={'select products'}>

                {errors.products && <span style={{ color: 'red' }}>{errors.products}</span>}
                <GlobalSelect
                  options={filteredProducts.map((product) => ({
                    value: product.id,
                    label: <div style={{ display: 'flex', alignItems: 'center' }}>
                      <img
                        src={product.image?.url || 'default-image-url'}
                        alt={product.title}
                        style={{ width: 40, height: 40, marginRight: 10 }}
                      />
                      <span>
                        {product.title} - ${product.price.normalPrice.find(p => p.currency === 'USD')?.amount || 0}
                      </span>
                    </div>
                  }))}
                  value={searchQuery ? { value: selectedProduct?.id, label: selectedProduct?.title } : null}
                  onChange={handleProductSelect}
                  placeholder="Search products..."
                  onInputChange={handleSearchChange}
                />


              </InputGroup>
            </InputGroupWrapper >
            <div style={{ marginTop: '20px' }}>
              <TableWrapper>

                <Styles>
                  {/* TODO add order summary eg total price, tax  */}
                  <table>
                    <thead>
                      <tr>
                        <th>Image</th>
                        <th>Product Name</th>
                        <th>Quantity</th>
                        <th>Price</th>
                        <th>Total</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.length === 0 ?
                        <tr>
                          <td colSpan="9" style={{ textAlign: 'center', padding: '20px' }}>
                            <div style={{ padding: '15px', borderRadius: '5px' }}>
                              No products selected. Please select products for this order.
                            </div>
                          </td>
                        </tr> : (tableData.map((item) => (
                          <tr key={item.id}>
                            <td><img src={item.thumbnail} style={{ width: '50px', height: '50px', objectFit: 'cover' }} /></td>
                            <td>{item.productName}</td>
                            <td>
                              <input
                                type="number"
                                value={item.quantity}
                                onChange={(e) => updateQuantity(item.id, e.target.value)}
                                min="1"

                                style={{ width: '60px' }}
                              />
                            </td>
                            <td>

                              <td>${item.price}</td>
                            </td>
                            <td>${item.total}</td>
                            <td>
                              <Button onClick={() => removeProductFromOrder(item.id)}>
                                Remove
                              </Button>
                            </td>
                          </tr>
                        )))}

                    </tbody>
                  </table>
                </Styles>
              </TableWrapper>
            </div>
            <BottomTotal>
              <TotalAmount>Total Amount</TotalAmount>
              <SidebarTitle>${order.totalAmount}</SidebarTitle>
            </BottomTotal>
          </Section>
          <Section>
            <InputGroupWrapper>
              <InputGroup label={'Order Notes'}>
                <GlobalTextarea placeholder={'Leave a note for your order (optional)'}
                  name="instructions"
                  value={instructions}
                  onChange={(e) => setInstructions(e.target.value)}
                />
              </InputGroup>
            </InputGroupWrapper>
          </Section>
          {(errors.whatsapp || errors.phone || errors.firstName || errors.lastName || errors.email) && (
            <span style={{ color: 'red', display: 'block', marginTop: '10px' }}>
              Please fill in all required fields.
            </span>
          )}
          {successMessage && <span style={{ color: 'green', display: 'block', marginTop: '12px' }}>
            Order created!
          </span>}
          {successDraftMessage && <span style={{ color: 'orange', display: 'block', marginTop: '12px' }}>
            Draft order created!
          </span>}
          <ButtonsWrapper>

            <GlobalButton
              type='primary'
              onClick={handleSubmit}
            >
              {loading ? <>
                <ColourRingLoader width={30} height={30} />

              </>
                :
                <PlusIcon />
              }
              Create Order
            </GlobalButton>

            <div>
              <GlobalButton type='orange-secondary' onClick={handleDraftSubmit}>
                {draftLoading ? <>
                  <ColourRingLoader width={30} height={30} />

                </>
                  :
                  <SaveIcon />
                }
                Save Draft
              </GlobalButton>
              {/* TODO Navigate back to all orders */}
              <GlobalButton type='gray' >
                <XSquareIcon />
                Cancel Order
              </GlobalButton>
            </div>
          </ButtonsWrapper>
        </Main>
        <Side isSidebarHidden={isSidebarHidden}>
          <CreateNewOderSidebar
            isSidebarHidden={isSidebarHidden}
            setIsSidebarHidden={setIsSidebarHidden}
          />
          <SidebarBackground />
        </Side>
      </Wrapper>
    </Container >
  );
};
