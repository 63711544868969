import Header from '../../components/header/header';
import { AuthPagesContainer } from '../../components/auth-pages-container/auth-pages-container';
import { GlobalInput } from '../../components/global-input/global-input';
import { InputGroup } from '../../components/input-group/input-group';
import { Description, ResetPasswordForm, SendBtn } from './reset-password-styled';
import { SvgIcon } from '../../components/svg-icon/svg-icon';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ColourRingLoader from 'components/loaders/colour-ring-loader/ColourRingLoader';
import { Alert } from 'pages/dashboard/components/alert/alert';
import { resetPassword, updatePassword, verifyResetKey } from '../../redux/slices/auth/authSlice';
import { Link, useSearchParams } from 'react-router-dom';
import { InputGroupWrapper } from 'pages/checkout/checkout-styled';
import { GlobalInputPassword } from 'components/global-input-password/global-input-password';
import PasswordStrengthBar from 'react-password-strength-bar';
import { getRouteById } from 'routes/routesHelper';
import { set } from 'lodash';

export const ResetPassword = () => {

    const isAuth = useSelector((state) => state.auth);

    const dispatch = useDispatch();

    const [err, setErr] = useState(null);
    const [success, setSuccess] = useState(false);
    const [email, setEmail] = useState('');

    const [ password, setPassword ] = useState('');
    const [ passwordConfirmation, setPasswordConfirmation ] = useState('');

    const [newPasswordForm, setNewPasswordForm] = useState(false);
    const [emailForm, setEmailForm] = useState(true);

    const [emailSent, setEmailSent] = useState(false);
    const [status, setStatus] = useState('idle');

    // Check if the url contains certain query parameters necessary for password reset
    const [searchParams] = useSearchParams();

    const action = searchParams.get('action');
    const key = searchParams.get('key');
    const login = searchParams.get('login');


    // Check if the key is valid
    useEffect(() => {
        if (key && login) {
            const details = {
                login,
                key
            }

            dispatch(verifyResetKey(details));

            if (isAuth.error) {
                setErr(isAuth.error);
            }
            if (status === 'success') {
                setErr(null);
                setSuccess({status:true, stage:'keyVerified', message: 'Key verified. Please enter your new password below.'}); 
                setNewPasswordForm(true);
            }
        }
    }, [key, login, dispatch]);


    const handleNewPassword = (e) => {
        e.preventDefault();

        if ( password !== '' && password === passwordConfirmation) {
            setErr(null);
            setSuccess(false);

            const details = {
                login,
                password,
                key
            }

            dispatch(updatePassword(details)).then((result) => {
                setStatus('loading');
                if (result.payload.message === 'success') {
                    setErr(null);
                    setSuccess({
                        status: true,
                        stage: 'resetComplete',
                        message: 'Your password has been successfully reset.'
                    });
                } else {
                    setSuccess(false);
                    setErr(result.payload);
                }
                setStatus('idle');
            });           

        } else {
            setSuccess(false);
            if( password === '' || passwordConfirmation === '') {
                setErr('Please enter all password fields');
            }else{
                setErr('Passwords do not match');
            }
        }
    }

    const handlePasswordReset = (e) => {
        e.preventDefault();
        dispatch( resetPassword(email) ).then((result) => {
            if(result.payload.message == 'success'){
                setErr(null);
                setSuccess(
                    {
                        status: true,
                        stage:  'emailSent',
                        message: 'A password reset link has been sent to your email. Please use it to set your new password.'
                    }
                );
                setEmailForm(false);
            }else{
                setSuccess(false);
                setErr(result.payload);
            }
        });
    }

    const elementRef = useRef(null);

    useEffect(() => {
        if (status === 'loading') {
            elementRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            elementRef.current.focus();
        }
    }, [status]);

    return (
        <>
            <Header />
            <AuthPagesContainer title={'Reset password'}>
                {
                    status === 'loading' ? (
                        <ColourRingLoader />
                        ) : success.status === true && success.stage !== 'keyVerified' ? (
                            <>
                                <Alert type={'success'}>{success.message}.</Alert>
                                {success.stage === 'resetComplete' && (
                                    <Link to={getRouteById('login')}>
                                        <SendBtn height={52} fullWidth>
                                            <SvgIcon name={''} />
                                            Login Now
                                        </SendBtn>
                                    </Link>
                                )}
                            </>
                        ) : (
                        err && <Alert type={'error'}>{err}</Alert>
                    )
                }
                <br />
                {
                    action === 'rp' && key && login && status !== 'loading' && newPasswordForm && success.stage === 'keyVerified' ? 
                    (
                        <>
                            <ResetPasswordForm>
                                <Description>Enter your new password below.</Description>
                                <InputGroupWrapper>
                                    <InputGroup label={'New Password'}>
                                        <GlobalInputPassword onChange={(e) => setPassword(e.target.value)} value={password} />
                                    </InputGroup>
                                    <PasswordStrengthBar password={password} />
                                </InputGroupWrapper>
                                <InputGroupWrapper>
                                    <InputGroup label={'Confirm Password'}>
                                        <GlobalInputPassword onChange={(e) => setPasswordConfirmation(e.target.value)} value={passwordConfirmation} />
                                    </InputGroup>
                                </InputGroupWrapper>
                                <SendBtn height={52} fullWidth onClick={handleNewPassword}>
                                    <SvgIcon name={''} />
                                    Change Password
                                </SendBtn>
                            </ResetPasswordForm>
                        </>
                    ) : (
                        status !== 'loading' && emailForm && (
                            <ResetPasswordForm>
                                <Description>
                                    Please enter your email address. You will receive a link to create a new password via email.
                                </Description>
                                <InputGroup label={'email'}>
                                    <GlobalInput placeholder={'Your email address'} onChange={(e) => setEmail(e.target.value)} />
                                </InputGroup>
                                <SendBtn height={52} fullWidth onClick={handlePasswordReset}>
                                    <SvgIcon name={'mail'} />
                                    Send
                                </SendBtn>
                            </ResetPasswordForm>
                        )
                    )}
            </AuthPagesContainer>
        </>
    );
};
