import { ReactComponent as MapPinIcon } from 'assets/icons/map-pin.svg';
import {
  BusinessSettingsSidebarStyled,
  Section,
  Map,
  Main,
  SecondaryTitle,
  Title,
  Avatar,
  Address,
  AddressText,
  AddressTop,
  TopInfo,
  ContactsItem,
  Subtitle,
  ContactsTable,
  ContactsWrapper,
  TitleWrapper,
  DescriptionText,
  Description,
  BannerImg,
  MobileBackBtn
} from './business-settings-sidebar-styled';

import map from '../img/sidebar-map.jpg';
import imgPlaceholder from '../img/sidebar-banner.jpg';
import logoPlaceholder from '../img/logoplaceholder.png';
import {
  OpenHours,
  OpenHoursItem,
  OpenHoursList,
  OpenHoursStatus,
  OpenHoursTitle,
  OpenHoursTop
} from '../../../../../components/store-front-top/store-front-top-styled';
import { SvgIcon } from '../../../../../components/svg-icon/svg-icon';
import { useSelector } from 'react-redux';
import { capitalize, upperCase } from 'lodash';
import { useEffect } from 'react';

export const BusinessSettingsSidebar = ({ isSidebarHidden, setIsSidebarHidden, businessInformation }) => {
  const openingTimes = useSelector((state) => state.businessSettingsReducer.openingTimes);

  const isOpenNow = "Open now";
  const { name, description, categories, whatsApp, phone, email, address, socials, logo, coverImage } = useSelector((state) => state.businessSettingsReducer);



  return (
    <BusinessSettingsSidebarStyled isSidebarHidden={isSidebarHidden}>
      <MobileBackBtn
        onClick={() => {
          setIsSidebarHidden(true);
        }}
      >
        <SvgIcon name={'chevron-left'} />
        Back to Business Settings
      </MobileBackBtn>
      <BannerImg>
        <img src={coverImage || imgPlaceholder} alt="Cover Banner" />
      </BannerImg>
      <Main>
        <TopInfo>
          <Avatar>
            <img src={logo || logoPlaceholder} alt="Logo" />

          </Avatar>
          <TitleWrapper>
            <Title>{name ? name : "Business Name"}</Title>
            <Subtitle>{categories && categories.length > 0 ? categories.map(selected => selected.name).join(',') :
              "Business Category"}</Subtitle>
          </TitleWrapper>

        </TopInfo>
        <Description>
          <SecondaryTitle>About Us</SecondaryTitle>
          <DescriptionText>
            {description ? description : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum  has been the industrys standard dummy text ever since the 1500s, when an unknown"}

          </DescriptionText>
        </Description>
        <Section>
          <Map>
            <img src={map} alt='' />
          </Map>
          <Address>
            <AddressTop>
              <MapPinIcon />
              <SecondaryTitle>Adress</SecondaryTitle>
            </AddressTop>
            <AddressText>
              {[address.shopAddress, address.streetAddress, address.suburb, address.city, address.province, address.country]
                .filter((value) => value)
                .join(', ')}
            </AddressText>
          </Address>
        </Section>
        <Section>
          <SecondaryTitle>Contacts</SecondaryTitle>
          <ContactsWrapper>
            <ContactsTable>
              <ContactsItem>
                <span>WhatsApp:</span>
                <span>{whatsApp ? whatsApp : "+263 71 77 562 446"}</span>
              </ContactsItem>
              <ContactsItem>
                <span>Phone:</span>
                <span>{phone ? String(phone) : "+263 71 77 562 446"}</span>
              </ContactsItem>
              <ContactsItem>
                <span>Email:</span>
                <span>{email ? email : "myemail@gmail.com"}</span>
              </ContactsItem>
              <ContactsItem>
                <span>Facebook:</span>
                <span className={'link'}>{socials.facebook || "https://facebook.com/facebook.com"}</span>

              </ContactsItem>
            </ContactsTable>
            <ContactsTable>
              <ContactsItem>
                <span>Instagram:</span>
                <span className={'link'}>{socials.instagram || "https://instagram.com/instagram.com"}</span>
              </ContactsItem>
              <ContactsItem>
                <span>Twitter:</span>
                <span className={'link'}>{socials.twitter || "https://twitter.com/twitter.com"}</span>
              </ContactsItem>
              <ContactsItem>
                <span>LinkedIn:</span>
                <span className={'link'}>{socials.linkedIn || "https://linkein.com/linkein.com"}</span>
              </ContactsItem>
              <ContactsItem>
                <span>Tiktok:</span>
                {socials.tiktok || "https://tiktok.com..."}
              </ContactsItem>
            </ContactsTable>
          </ContactsWrapper>
        </Section>
        <Section>
          <OpenHours>
            <OpenHoursTop>
              <OpenHoursTitle>Opening Times</OpenHoursTitle>
              <OpenHoursStatus>{isOpenNow}</OpenHoursStatus>
            </OpenHoursTop>
            <OpenHoursList>
              {Object.entries(openingTimes).map(([day, times]) => (
                <OpenHoursItem key={day}>
                  <span>{capitalize(day.substring(0, 3))}</span>&nbsp;&nbsp; {/* Display first 3 letters of the day */}
                  <span>
                    {times.disabled
                      ? "Closed"
                      : `${times.open || "08:00"} - ${times.close || "17:00"}`}
                  </span>
                </OpenHoursItem>
              ))}
            </OpenHoursList>
          </OpenHours>
        </Section>
      </Main>
    </BusinessSettingsSidebarStyled>
  );
};
