import {
  ButtonWrapper,
  CardsWrapper,
  Container,
  FilterWrapper,
  Main,
  MainTop,
  MobileFilterBtn,
  MobileSort,
  SearchWrapper
} from './section-styled';
import InputWithLabel from 'components/input-with-label/input-with-label';
import { GlobalButton } from 'components/global-button/global-button';
import { SvgIcon } from 'components/svg-icon/svg-icon';
import { SortWithIcon } from 'components/sort-with-icon/sort-with-icon';
import { ProductsFilter } from 'components/products-filter/products-filter';
import React from 'react';
import { ResultsPerPage } from '../../../../components/results-per-page/results-per-page';
import Filters from '../../../categories/components/filters/filters';
import { GlobalFilter } from '../../../../components/global-filter/global-filter';
import { MobSort } from '../../../../components/mob-sort/mob-sort';
import { MobSearch } from '../../../../components/mob-search/mob-search';

export const Section = ({
  children,
  button,
  isListView,
  isSmallCardsOnMobile,
  searchBarLabel,
  isMobileFilterVisible,
  setMobileFilterVisible,
  business,
  setSearchTerm,
  categories,
  setSelectedCategory,
  setPriceRange,
  handleSearchAndFilters,
  products

}) => {
  return (
    <Container>
      {/* TODO Filter products by categories,price and attributes */}
      {/* <GlobalFilter
        business={business}
        setPriceRange={setPriceRange}
        categories={categories}
        isMobileFilterVisible={isMobileFilterVisible}
        setSelectedCategory={setSelectedCategory}
        onCloseFilterBtnClick={() => {
          setMobileFilterVisible(false);
        }}

      /> */}

      <Main>
        <MainTop>
          <MobileFilterBtn>
            <GlobalButton
              height={40}
              onClick={() => {
                setMobileFilterVisible(true);
              }}
            >
              <SvgIcon name={'filter'} />
              Filters
            </GlobalButton>
          </MobileFilterBtn>
          <SearchWrapper>
            <InputWithLabel height={40} name={searchBarLabel}
              onChange={(e) => {

                setSearchTerm(e.target.value); // Update search term state
                handleSearchAndFilters(products); // Pass the correct array
              }
              } />
          </SearchWrapper>
          <ResultsPerPage />
          <MobSearch />
          <MobSort />
        </MainTop>
        <CardsWrapper isListView={isListView} isSmallCardsOnMobile={isSmallCardsOnMobile}>
          {children}
        </CardsWrapper>
        {button && <ButtonWrapper>{button}</ButtonWrapper>}
      </Main>
    </Container>
  );
};
