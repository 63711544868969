import styled from 'styled-components';
import { device } from '../../../../assets/style-helpers/breakpoints';
import { LatoFont } from '../../../../assets/style-helpers/style-helpers';
import { title30 } from '../../../../assets/style-helpers/typography';

export const Container = styled.section`
  padding-bottom: 110px;
  padding-right: 40px;

  @media ${device.tablet} {
    padding-bottom: 60px;
    padding-right: 0;
  }

  @media ${device.mobile} {
    padding-bottom: 48px;
  }
`;

export const Title = styled.div`
  ${title30};
  margin-bottom: 10px;
`;

export const Subtitle = styled.div`
  ${LatoFont};
  color: var(--secondary-text);
`;

export const Label = styled.p`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #29263a;
  margin-bottom: 8px;
`;

export const Navigation = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 28px;
  margin-top: 17px;
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  max-width: 282px;
  width: 100%;

  @media ${device.tablet} {
    max-width: unset;
    margin-right: 16px;
  }

  @media ${device.mobile} {
    display: none;
  }
`;

export const MobileSearch = styled.div`
  display: none;

  @media ${device.mobile} {
    display: flex;
    align-items: center;

    svg {
      margin-right: 11px;
    }

    p {
      font-family: 'Lato', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      color: var(--main-text);
    }
  }
`;

export const SearchInput = styled.input`
  width: 100%;
  border: 1px solid var(--divider);
  border-radius: 6px;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: var(--main-text);
  padding: 6px 0 6px 18px;
  outline: none;
`;

export const SearchButton = styled.div`
  position: absolute;
  right: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--main-green);
  max-height: 32px;
  border-radius: 6px;
  padding: 9px;
  cursor: pointer;
`;

export const ButtonWrapper = styled.div`
  min-width: 180px;

  @media ${device.tablet} {
    margin-left: 16px;
    min-width: auto;
    > button {
      padding: 0;
      width: 40px;
      height: 40px;
      svg {
        margin-right: 0;
      }
      > p {
        display: none;
      }
    }
  }
`;

export const Content = styled.div`
  padding-bottom: 106px;
  @media ${device.tablet} {
    padding-bottom: 0;
  }
  @media ${device.mobile} {
    padding-bottom: 0;
  }
`;

export const TagsWrapper = styled.div`
  display: flex;
  max-width: 202px;
  flex-wrap: nowrap;
  margin: 15px 0;
`;
export const Tag = styled.div`
  width: max-content;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: var(--main-text);
  background: #f7f7f7;
  border-radius: 10px;
  padding: 4px 12px;
  margin: 0 6px 6px 0;
`;

export const DropdownsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 16px;

  @media ${device.tablet} {
    display: none;
  }

  @media ${device.mobile} {
    display: none;
  }

  > div {
    width: 160px;
    height: 38px;
    margin-right: 12px;

    > p {
      margin-right: 9px;
    }
  }
`;

export const LeftSide = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  @media ${device.mobile} {
    position: relative;
  }
`;

export const FilterButton = styled.button`
  display: none;
  justify-content: space-between;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  color: #ffffff;
  padding: 14px 20px;
  background-color: var(--main-green);
  border: none;
  border-radius: 6px;
  margin-right: 20px;
  cursor: pointer;

  @media ${device.tablet} {
    display: flex;
    align-items: center;
    width: 130px;
    height: 40px;
    font-size: 14px;
    padding: 11px 26px;

    img {
      margin-right: 11px;
    }
  }

  @media ${device.mobile} {
    display: flex;
    align-items: center;
    width: 130px;
    height: 40px;
    font-size: 14px;
    padding: 11px 26px;

    img {
      margin-right: 11px;
    }
  }
`;

export const StyledId = styled.div`
  display: flex;
  align-items: center;

  > div {
    margin-right: 10px;
  }
`;

export const CheckboxWrapper = styled.div`
  input {
    border-color: var(--main-text) !important;
  }
`;

export const CheckboxWrapperHeader = styled.div`
  input {
    border-color: var(--main-subtext) !important;
    background: #f7f7f7 !important;
  }
`;

export const Section = styled.div`
  background-color: var(--main-white);
  box-shadow: 0 4px 60px rgba(62, 73, 59, 0.06);
  border-radius: 16px;
  padding: 28px 32px 40px 32px;
  margin-bottom: 32px;

  > div {
    margin-bottom: 18px;
  }
`;

export const SecondSection = styled.div`
  background-color: var(--main-white);
  box-shadow: 0 4px 60px rgba(62, 73, 59, 0.06);
  border-radius: 16px;
  padding: 28px 32px 34px 32px;
  margin-bottom: 32px;
  @media ${device.tablet} {
    padding: 28px 15px 34px 15px;
  }

  @media ${device.mobile} {
    padding: 28px 15px 34px 15px;
  }
`;

export const SidebarTitle = styled.p`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: var(--main-text);
  margin-bottom: 18px;
`;

export const SidebarSubtitle = styled.p`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: var(--main-text);
  margin: 10px 0 8px 0;
`;

export const DropdownWrapper = styled.div`
  > div {
    padding: 4px 8px;

    p {
      margin-right: 20px;
    }

    img {
      margin-right: unset;
    }
  }
`;

export const SidebarInputWrapper = styled.div`
  > input {
    padding: 4px 7.5px;
    height: unset;
    text-align: center;
  }
`;

export const Thumbnail = styled.img``;

export const ButtonsWrapper = styled.div`
  display: flex;
  margin-top: 32px;
  justify-content: space-between;

  button + button {
    margin-left: 16px;
  }
`;

export const TextInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;

  :last-child {
    margin-bottom: unset;
  }

  > p {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: var(--main-text);
  }

  > h5 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: var(--main-text);
    margin: unset;
    max-width: 320px;
    text-align: end;
  }
`;

export const Social = styled.div`
  display: flex;
  align-items: center;
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 9px;

    img {
      width: 18px;
      height: 18px;
    }
  }

  .whatsapp {
    background: var(--main-green);
    margin-right: 14px;
    cursor: pointer;
    filter: drop-shadow(0px 4px 10px rgba(0, 100, 0, 0.3));
  }

  .phone {
    background: var(--main-green);
    margin-right: 14px;
    cursor: pointer;
    filter: drop-shadow(0px 4px 10px rgba(0, 100, 0, 0.3));
  }
`;

export const HorizontalLine = styled.div`
  width: 100%;
  border-bottom: 1px solid var(--main-gray-2);
  margin: 32px 0;
`;

export const SecondDropdownWrapper = styled.div`
  display: flex;
  align-items: center;
  > div {
    padding: 4px 12px;
    margin-right: 25px;
    p {
      margin-right: 45px;
    }
    img {
      margin-right: unset;
    }
  }
`;

export const ChangeStatus = styled.p`
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: var(--main-green);
  text-decoration: underline;
  cursor: pointer;
`;

export const BottomTotal = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  > p {
    :last-child {
      margin-bottom: unset;
    }
  }
`;

export const TotalAmount = styled.p`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 26px;
  color: var(--main-text);
`;
