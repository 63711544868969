import {
  InputGroupWrapper,
  Section,
  SectionTitle
} from '../../add-new-product-single/add-new-product-single-styled';
import { InputGroup } from '../../../../../components/input-group/input-group';
import { GlobalInput } from '../../../../../components/global-input/global-input';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { updateSocialsField } from '../../../../../redux/slices/businessSettingsSlice';
import LinksInputWithLabel from 'components/input-with-label/links-input-with-label';

export const SocialMediaSection = () => {
  const dispatch = useDispatch();
  const { socials } = useSelector(
    (state) => state.businessSettingsReducer
  );

  const handleSocialsChange = (field) => (event) => {
    const value = event.target.value;
    dispatch(updateSocialsField({ field, value }));
  };
  return (
    <Section>
      <SectionTitle>Social Media Accounts</SectionTitle>

      <InputGroupWrapper>
        <InputGroup label={'facebook'}>
          <LinksInputWithLabel
            height={40}
            name='https://facebook.com/'
            placeholder={''}
            value={socials.facebook?.replace('https://facebook.com/', '')}
            onChange={handleSocialsChange('facebook')}
          />
        </InputGroup>

      </InputGroupWrapper>

      <InputGroupWrapper>
        <InputGroup label={'instagram'}>
          <LinksInputWithLabel
            height={40}
            name='https://instagram.com/'
            placeholder={''}
            value={socials.instagram?.replace('https://instagram.com/', '')}
            onChange={handleSocialsChange('instagram')}
          />
        </InputGroup>

      </InputGroupWrapper>


      <InputGroupWrapper>
        <InputGroup label={'twitter'}>
          <LinksInputWithLabel
            height={40}
            name='https://twitter.com/'
            placeholder={''}
            value={socials.twitter?.replace('https://twitter.com/', '')}
            onChange={handleSocialsChange('twitter')}
          />
        </InputGroup>

      </InputGroupWrapper>


      <InputGroupWrapper>
        <InputGroup label={'linkedin'}>
          <LinksInputWithLabel
            height={40}
            name='https://linkedin.com/'
            placeholder={''}
            value={socials.linkedin?.replace('https://linkedin.com/', '')}
            onChange={handleSocialsChange('linkedin')}
          />
        </InputGroup>

      </InputGroupWrapper>

      <InputGroupWrapper>
        <InputGroup label={'tiktok'}>
          <LinksInputWithLabel
            height={40}
            name='https://tiktok.com/'
            placeholder={''}
            value={socials.tiktok?.replace('https://tiktok.com/', '')}
            onChange={handleSocialsChange('tiktok')}
          />
        </InputGroup>

      </InputGroupWrapper>

    </Section>
  );
};
