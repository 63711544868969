import { MobileBackBtn } from '../../business-information/components/business-settings-sidebar-styled';
import { SvgIcon } from '../../../../../components/svg-icon/svg-icon';
import {
  CardsWrapper,
  CreateNewOderSidebarStyled,
  HelpCardStyled,
  PLayBtn,
  VideoInfo,
  VideoWrapper
} from '../create-new-order-styled';
import { SectionTitle } from '../../add-new-product-single/add-new-product-single-styled';

import { ReactComponent as PlayIcon } from '../icons/play.svg';
import videoImg from 'assets/temp-img/video-tutorial.png';

const HelpCard = () => {
  return (
    <HelpCardStyled>
      <VideoWrapper>
        <PLayBtn>
          <PlayIcon />
        </PLayBtn>
        <img src={videoImg} alt='' />
      </VideoWrapper>
      <VideoInfo>
        <div>How To Upload A Product</div>
        <div>01:30</div>
      </VideoInfo>
    </HelpCardStyled>
  );
};

export const CreateNewOderSidebar = ({ isSidebarHidden, setIsSidebarHidden }) => {
  return (
    <CreateNewOderSidebarStyled isSidebarHidden={isSidebarHidden}
      style={{
        position: 'sticky',
        top: '120px',
        alignSelf: 'flex-start',
        marginBottom: '90%',
        height: '100vh'
      }}>


      <MobileBackBtn
        onClick={() => {
          setIsSidebarHidden(true);
        }}
      >
        <SvgIcon name={'chevron-left'} />
        Back to Business Settings
      </MobileBackBtn>
      <SectionTitle>Help & Tutorials</SectionTitle>
      <CardsWrapper>
        <HelpCard />
        <HelpCard />
        <HelpCard />
        <HelpCard />
        <HelpCard />
      </CardsWrapper>
    </CreateNewOderSidebarStyled>
  );
};
