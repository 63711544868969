export const ROUTES = {
    // Front-end Routes
    HOME: { id: 'home', path: '/' },

    // Authentication Routes
    LOGIN: { id: 'login', path: '/login' },
    REGISTER: { id: 'register', path: '/register' },
    PASSWORD_RESET: { id: 'passwordReset', path: '/password-reset' },

    // Dashboard Routes - Orders
    DASHBOARD: { id: 'dashboard', path: '/dashboard' },
    ALL_ORDERS: { id: 'allOrders', path: '/dashboard/orders' },
    NEW_ORDERS: { id: 'newOrders', path: '/dashboard/orders/new-orders' },
    MY_ORDERS: { id: 'myOrders', path: '/dashboard/orders/my-orders' },
    ORDER: { id: 'order', path: '/dashboard/order/:id' },
    CREATE_ORDER: { id: 'createOrder', path: '/dashboard/orders/create-order' },

    B_INFO: { id: 'businessInformation', path: '/dashboard/settings/business-information' },

    // Storefront Routes
    STOREFRONT: { id: 'storefront', path: '/vendor/:bID' },
};