import { Container, Main, Sub, Subcategory } from './category-styled';
import { useState } from 'react';

export const Category = ({ title, qty, onClick }) => {
  const [isSubOpened, setIsSubOpened] = useState(false);
  return (
    <Container>
      <Main
        onClick={onClick}
      >
        {title}
        <span>{qty}</span>
      </Main>
    </Container>
  );
};
