import { OtherStoresSidebar } from '../store-front-trades-services/components/other-stores-sidebar/other-stores-sidebar';
import {
  ButtonsTop,
  ButtonWrapper,
  Container
} from '../store-front-trades-services/store-front-trades-services-styled';
import { Breadcrumbs } from '../../components/breadcrumbs/breadcrumbs';
import { StoreFrontTop } from '../../components/store-front-top/store-front-top';
import { TradesServicesStoreMock } from '../store-front-trades-services/mock/trades-services-mock';
import Reviews from '../company-page/components/reviews/reviews';
import { OurServices } from '../store-front-trades-services/components/our-services/our-services';
import { OurProducts } from '../store-front-trades-services/components/our-products/our-products';
import { useEffect, useState } from 'react';
import { ApointmentBookingPopup } from '../store-front-trades-services/components/apointment-booking-popup/apointment-booking-popup';
import { GlobalButton } from '../../components/global-button/global-button';
import { SvgIcon } from '../../components/svg-icon/svg-icon';
import { StoreFrontProductSection } from '../../components/store-front-product-section/store-front-product-section';
import { ServiceCard } from '../store-front-trades-services/components/our-services/components/service-card/service-card';
import { viewType } from '../../components/view-selection/view-selection';
import { generalProductsMock } from '../../dummy-data/generalProductsMock';
import { PCard, ProductCardTypes } from '../../components/p-card/p-card';
import { LoadMore } from '../../components/load-more/load-more';
import axios from 'axios';
import { Products } from 'pages/single-product-general/single-product-general-styled';
import { set } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { getProducts } from '../../redux/slices/product/productSlice';
import { useParams } from 'react-router';
import { getBusiness } from '../../redux/slices/business/businessSlice';
import productLoaderDefault from 'components/loaders/product/productLoaderDefault';
import { getRouteById } from 'routes/routesHelper';

const StorefrontGeneralButtons = ({ onOtherStoreBtnClick }) => {
  const [isApointmentBookingVibisle, setIsApointmentBookingVibisle] = useState(false);
  const [isFollowed, setIsFollowed] = useState(false);

  return (
    <ButtonWrapper>
      <ApointmentBookingPopup
        isApointmentBookingVibisle={isApointmentBookingVibisle}
        onClose={() => {
          setIsApointmentBookingVibisle(false);
        }}
      />
      <ButtonsTop>
        <GlobalButton
          onClick={() => {
            setIsApointmentBookingVibisle(true);
          }}
        >
          <SvgIcon name={'annotation-check'} />
          Book An Appointment
        </GlobalButton>
        <GlobalButton
          checked={isFollowed}
          type={'orange-secondary'}
          onClick={() => {
            setIsFollowed(!isFollowed);
          }}
        >
          <SvgIcon name={'bell'} />
          {isFollowed ? 'Followed' : 'Follow'}
        </GlobalButton>
      </ButtonsTop>
      <GlobalButton type={'orange'} fullWidth onClick={onOtherStoreBtnClick}>
        <SvgIcon name={'building'} />
        Our other stores
      </GlobalButton>
    </ButtonWrapper>
  );
};

export const StoreFrontGeneralStore = () => {

  const [searchTerm, setSearchTerm] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [priceRange, setPriceRange] = useState({ min: 0, max: 100000 });
  // TODO Get business id from URL 
  const { bID } = useParams();
  console.log('bID', bID);
  const productsBID = 80;
  const dispatch = useDispatch();
  const [products, setProducts] = useState([])
  const [business, setBusiness] = useState(null)
  const [filteredProducts, setFilteredProducts] = useState();
  const pState = useSelector((state) => state.product.status);
  // console.log('pState', pState);
  useEffect(() => {
    const params = {
      bID: bID,
    }
    const pParams = {
      bID: productsBID,
    }
    dispatch(getProducts(pParams)).then((result) => {
      // console.log('result', result);

      setProducts(result.payload);
      setFilteredProducts(result.payload);

    });

    dispatch(getBusiness(params)).then((result) => {
      setBusiness(result.payload.data);
    });

  }, [bID]);



  // console.log('filteredProducts', filteredProducts);
  const handleSearchAndFilters = (products) => {
    const filteredProducts = products

      .filter((product) =>
        searchTerm ? product.title.toLowerCase().includes(searchTerm.toLowerCase()) : true
      );
    // .filter((product) =>
    //   selectedCategory
    //     ? categories.find((cat) => cat.name === selectedCategory)?.products.includes(product.id)
    //     : true
    // )
    // .filter((product) => {

    //   return product.price.sellingPrice.find(price => price.currency === "USD")?.amount >= priceRange.min
    //     && product.price.sellingPrice.find(price => price.currency === "USD")?.amount <= priceRange.max
    // });

    setProducts(filteredProducts);
  }


  const [isOtherStoresVisible, setIsOtherStoresVisible] = useState(false);
  const [productsToShow, setProductsToShow] = useState(12);


  const breadcrumbs = [
    { link: getRouteById('home') , name: 'Home' },
    { link: '#', name: 'Vendor' },
    { link: '#', name: `${business ? business.name : "Business name"}` }
  ];

  const handleAddMore = () => {
    setProductsToShow(prev => prev + 8);
  }

  return (
    <>
      <OtherStoresSidebar
        isVisible={isOtherStoresVisible}
        onCloseBtnClick={() => {
          setIsOtherStoresVisible(false);
        }}
        title={'Other Stores Of This Vendor'}
      />
      <Container>
        <Breadcrumbs links={breadcrumbs} />
        <StoreFrontTop
          storeInfo={business}
        // TODO book an appointment and view other stores of this vendor
        // customButtonSection={() => (
        //   <StorefrontGeneralButtons
        //     onOtherStoreBtnClick={() => {
        //       setIsOtherStoresVisible(true);
        //     }}
        //   />
        // )}
        />
        {/* TODO reviews and comments in storefront */}
        {/* <Reviews slidesToShow={4} title={'What Our Clients Are Saying'} /> */}

        <StoreFrontProductSection
          searchBarLabel={'Search'}
          setSearchTerm={setSearchTerm}
          additionalBtn={productsToShow < products.length && <LoadMore onClick={handleAddMore} />}
          categories={categories} setSelectedCategory={setSelectedCategory} setPriceRange={setPriceRange}
          handleSearchAndFilters={handleSearchAndFilters}
          products={filteredProducts}
        >
          {pState === 'loading' ? <productLoaderDefault /> :
            products.length <= 0
              ? <div style={{ textAlign: 'center', fontSize: '14px' }}></div>
              : products.slice(0, productsToShow).map((product, index) => {
                return <PCard
                  product={product}
                  type={ProductCardTypes.STORE}
                  key={index} business={business}
                />
              })
          }


        </StoreFrontProductSection >

      </Container >
    </>
  );
};
