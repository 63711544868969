import React from 'react'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { update } from 'lodash';
import localStorage from 'redux-persist/es/storage';

const productsURL = process.env.REACT_APP_API_ENDPOINT + process.env.REACT_APP_PRODUCTS_API_URL;


const initialState = {
    products: [],
    status: 'idle',
    error: null,
};

export const getProducts = createAsyncThunk(
    'products/getBusinessProducts',
    async (params, { rejectWithValue }) => {
        const { bID } = params

        try {
            const response = await axios.post(productsURL,
                {
                    bID
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data;
        } catch (err) {
            console.log(err);
            return rejectWithValue(err.response?.data?.message);
        }
    }
);


const productSlice = createSlice({
    name: 'products',
    initialState,
    extraReducers: (builder) => {
        builder
            .addMatcher(
                (action) => action.type.endsWith('/pending'),
                (state) => {
                    state.status = 'loading';
                    state.error = null; // Clear any previous errors
                }
            )
            // Handle fulfilled states for both login and loginWithEmail
            .addMatcher(
                (action) => action.type.endsWith('/fulfilled'),
                (state, action) => {
                    const products = action.payload;
                    state.status = 'success';
                    state.products = products?.data;
                    state.error = null;
                }
            )

            // Handle rejected states for both login and loginWithEmail
            .addMatcher(
                (action) => action.type.endsWith('/rejected'),
                (state, action) => {
                    state.status = 'rejected';
                    state.error = action.payload;
                }
            )
    },
});

export default productSlice.reducer;