import { RateStars } from '../rate-stars/rate-stars';
import { GlobalButton } from '../global-button/global-button';
import {
  AboutUs,
  AboutUsDescription,
  AboutUsTitle,
  Address,
  AddressMain,
  AddressText,
  AddressTitle,
  Banner,
  Container,
  FollowBtnWrapper,
  Followers,
  Info,
  InfoContent,
  InfoLogo,
  MainInfo,
  MainInfoDescription,
  MainInfoTop,
  Map,
  OpenHours,
  OpenHoursItem,
  OpenHoursList,
  OpenHoursStatus,
  OpenHoursTitle,
  OpenHoursTop,
  RatingWrapper,
  Reviews,
  Social,
  SocialInfoLinks,
  SocialItemLink,
  SocialLinks,
  SocialTitle,
  Sold,
  Subtitle,
  Title
} from './store-front-top-styled';
import tempMap from './img/map.jpg';
import mapIcon from './img/location.svg';
import { SvgIcon } from '../svg-icon/svg-icon';
import { useEffect, useState } from 'react';
import CoverImageLoader from 'components/loaders/storefront/coverImageLoader';
import { useSelector } from 'react-redux';
import LogoLoader from 'components/loaders/storefront/LogoLoader';
import descriptionLoader from 'components/loaders/storefront/descriptionLoader';
// const socialLinks = ['facebook', 'twitter', 'instagram'];
const socialLinks = ['instagram', 'youtube', 'twitter', 'linkedin', 'tiktok', 'facebook'];

export const StoreFrontTop = ({ storeInfo, customButtonSection }) => {
  // if (!storeInfo) {
  //      return <div>Loading...</div>; //while data is being fetched
  // }
  // const { coverImage, name, categories, rate, reviews, description, address, openingTimes, logo, phone, email, whatsApp, website, socials } = storeInfo;

  const [status, setStatus] = useState('idle');
  const bStatus = useSelector((state) => state.business.status);

  useEffect(() => {
    setStatus(bStatus);
  }, [bStatus]);
  console.log(bStatus);

  const [isFollowed, setIsFollowed] = useState(false);
  console.log('StoreInfo', storeInfo);
  return (
    <Container>
      <Banner>
        {
          status !== 'success' ? <CoverImageLoader /> : <img src={storeInfo === null ? '' : storeInfo?.coverImage} alt={storeInfo?.name} />
        }
      </Banner>
      <MainInfo>
        <MainInfoTop>
          <Info>

            {
              status !== 'success' ? <LogoLoader /> :
                <>
                  <InfoLogo>

                    <img src={storeInfo?.logo} alt={storeInfo?.name} />

                  </InfoLogo>
                  <InfoContent>


                    <Title>{storeInfo?.name}</Title>
                    <Subtitle>
                      {
                        storeInfo?.categories.map(category => category.name).join(', ')
                      }

                    </Subtitle>
                    {/* TODO Business ratings and reviewss */}
                    {/* 
              <RatingWrapper>
                {
                  status !== 'success' ? <CoverImageLoader /> :
                    <>
                      <RateStars
                        rate={rate}
                        height={17}
                        width={17}
                        isRateCounter={false}
                        isMobileMinified={false}
                      />
                      <Reviews>{reviews} Reviews</Reviews>
                      <Reviews> Reviews</Reviews>
                    </>
                }
                <Sold>{propertiesSold} Properties Sold</Sold>
              </RatingWrapper> */}
                  </InfoContent>
                </>
            }
          </Info>
          {customButtonSection ? (
            customButtonSection()
          ) : (""
            // TODO storefront followers
            // <FollowBtnWrapper>
            //   <GlobalButton
            //     height={40}
            //     checked={isFollowed}
            //     onClick={() => {
            //       setIsFollowed(!isFollowed);
            //     }}
            //     type={'green'}
            //   >
            //     <svg
            //       width='16'
            //       height='18'
            //       viewBox='0 0 16 18'
            //       fill='none'
            //       xmlns='http://www.w3.org/2000/svg'
            //     >
            //       <path
            //         d='M5.88068 16.2C6.4455 16.6979 7.18742 17 8 17C8.81258 17 9.5545 16.6979 10.1193 16.2M12.806 5.8C12.806 4.52696 12.2997 3.30606 11.3984 2.40589C10.4971 1.50571 9.27464 1 8 1C6.72536 1 5.50293 1.50571 4.60163 2.40589C3.70032 3.30606 3.19398 4.52696 3.19398 5.8C3.19398 8.27215 2.56957 9.96477 1.87205 11.0843C1.28368 12.0287 0.989499 12.5009 1.00029 12.6326C1.01223 12.7785 1.04317 12.8341 1.16085 12.9213C1.26713 13 1.74623 13 2.70445 13H13.2956C14.2538 13 14.7329 13 14.8392 12.9213C14.9568 12.8341 14.9878 12.7785 14.9997 12.6326C15.0105 12.5009 14.7163 12.0287 14.1279 11.0843C13.4304 9.96477 12.806 8.27215 12.806 5.8Z'
            //         stroke='white'
            //         strokeWidth='2'
            //         strokeLinecap='round'
            //         strokeLinejoin='round'
            //       />
            //     </svg>
            //     {isFollowed ? 'Followed' : 'Follow'}
            //   </GlobalButton>
            //   <Followers>
            //     <svg
            //       width='14'
            //       height='14'
            //       viewBox='0 0 14 14'
            //       fill='none'
            //       xmlns='http://www.w3.org/2000/svg'
            //     >
            //       <path
            //         d='M12.8346 12.25V11.0833C12.8346 9.99609 12.091 9.08253 11.0846 8.82351M9.04297 1.91961C9.89809 2.26575 10.5013 3.1041 10.5013 4.08333C10.5013 5.06257 9.89809 5.90091 9.04297 6.24706M9.91797 12.25C9.91797 11.1628 9.91797 10.6192 9.74035 10.1904C9.50353 9.61867 9.0493 9.16443 8.47756 8.92761C8.04876 8.75 7.50517 8.75 6.41797 8.75H4.66797C3.58077 8.75 3.03717 8.75 2.60837 8.92761C2.03664 9.16443 1.5824 9.61867 1.34558 10.1904C1.16797 10.6192 1.16797 11.1628 1.16797 12.25M7.8763 4.08333C7.8763 5.372 6.83163 6.41667 5.54297 6.41667C4.2543 6.41667 3.20964 5.372 3.20964 4.08333C3.20964 2.79467 4.2543 1.75 5.54297 1.75C6.83163 1.75 7.8763 2.79467 7.8763 4.08333Z'
            //         stroke='#388638'
            //         strokeWidth='1.5'
            //         strokeLinecap='round'
            //         strokeLinejoin='round'
            //       />
            //     </svg>
            //     <span>250 Followers</span>
            //   </Followers>
            // </FollowBtnWrapper>
          )}
        </MainInfoTop>
        <MainInfoDescription>
          <AboutUs>
            <AboutUsTitle>About Us</AboutUsTitle>
            {
              status !== 'success' ? <descriptionLoader /> : <AboutUsDescription>{storeInfo?.description}</AboutUsDescription>

            }
          </AboutUs>
          <OpenHours>
            <OpenHoursTop>
              <OpenHoursTitle>Opening Times</OpenHoursTitle>
              <OpenHoursStatus>Open now</OpenHoursStatus>
            </OpenHoursTop>
            <OpenHoursList>

              {
                status !== 'success' ? <descriptionLoader /> :
                  storeInfo?.openingTimes
                    ? Object.entries(storeInfo?.openingTimes).map(([day, times], index) => (
                      <OpenHoursItem key={index}>
                        <span>{day.charAt(0).toUpperCase() + day.slice(1, 3)}:</span>
                        <span>
                          {!times || times.disabled || !times.open || !times.close
                            ? "Closed"
                            : `${times.open}-${times.close}`}
                        </span>
                      </OpenHoursItem>
                    ))
                    : 'Error'
              }


            </OpenHoursList>
          </OpenHours>
        </MainInfoDescription>
        <Social>
          <SocialTitle>Our Social Media</SocialTitle>
          <SocialInfoLinks>
            {
              status !== 'success' ? <descriptionLoader /> :
                socialLinks.map((social, i) => {
                  const link = storeInfo?.socials[social];

                  return (
                    link && (
                      <SocialItemLink key={i} to={`${link}`} target="_blank" rel="noopener noreferrer">

                        <SvgIcon name={social} />

                      </SocialItemLink>
                    )
                  );
                })
            }

          </SocialInfoLinks>
        </Social>
      </MainInfo>
      <Address>
        <Map>
          <img src={tempMap} alt='map' />
        </Map>
        <AddressMain>
          <AddressTitle>
            <img src={mapIcon} alt='icon' />
            Address
          </AddressTitle>
          {
            status !== 'success' ? <descriptionLoader /> :
              <AddressText>
                {[
                  storeInfo?.address.shopAddress,
                  storeInfo?.address.streetAddress,
                  storeInfo?.address.surburb,
                  storeInfo?.address.city,
                  storeInfo?.address.province,
                  storeInfo?.address.country
                ]
                  .filter((item) => item)
                  .join(', ')}
              </AddressText>
          }
          <SocialLinks>
            {status !== 'success' ? <descriptionLoader />
              :
              <>
                <a href={`https://wa.me/${storeInfo?.whatsApp}?text=Hello,%20I%20found%20your%20store%20on%20Everything%20Zimbabwean%20and%20I%20would%20like%20to%20know%20more%20about%20your%20products`}
                  type="tel" target='_blank'>
                  <SvgIcon name="whatsup" />
                </a>

                <a href={`tel:${storeInfo?.phone}`} target='_blank'>
                  <SvgIcon name={'phone'} />
                </a>
                <a href={`mailto:${storeInfo?.email}`} target='_blank'>
                  <SvgIcon name={'email'} />
                </a>
              </>
            }

          </SocialLinks>
        </AddressMain>
      </Address>
    </Container>
  );
};
