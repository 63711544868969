import {
  Container,
  Title,
  TitleWrapper
} from '../../pages/store-front-trades-services/components/our-services/our-services-styled';
import { ViewSelection, viewType } from '../view-selection/view-selection';
import { Section } from '../../pages/store-front-trades-services/components/section/section';
import { useState } from 'react';

export const StoreFrontProductSection = ({
  searchBarLabel,
  additionalBtn,
  currentViewType,
  setCurrentViewType,
  children,
  setSearchTerm,
  business,
  categories,
  setSelectedCategory,
  setPriceRange,
  handleSearchAndFilters,
  products
}) => {

  return (
    <Container>
      <TitleWrapper>
        <Title>Our Products</Title>
        {currentViewType && (
          <ViewSelection
            setCurrentViewType={setCurrentViewType}
            currentViewType={currentViewType}
            isMobileHidden={false}
          />
        )}
      </TitleWrapper>
      <Section
        button={additionalBtn}
        isListView={currentViewType === viewType.list}
        searchBarLabel={searchBarLabel}
        setSearchTerm={setSearchTerm}
        business={business}
        categories={categories}
        setSelectedCategory={setSelectedCategory}
        setPriceRange={setPriceRange}
        handleSearchAndFilters={handleSearchAndFilters}
        products={products}
      >
        {children}
      </Section>
    </Container>
  );
};
