import Select from 'react-select';

export const customDropdownIndicator = ({ innerProps, innerRef }) => {
  return (
    <div ref={innerRef} {...innerProps} style={{ padding: '8px 8px 8px 8px', marginRight: '10px' }}>
      <svg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M1 1L7 7L13 1'
          stroke='#ACADB9'
          strokeWidth='1.6'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </div>
  );
};

export const customDropdownIndicatorSmall = ({ innerProps, innerRef }) => {
  return (
    <div ref={innerRef} {...innerProps} style={{ padding: '4px 4px 4px 4px', marginRight: '4px' }}>
      <svg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M1 1.5L4 4.5L7 1.5'
          stroke='#ACADB9'
          strokeWidth='1.7'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </div>
  );
};

const selectCustomStyle = {
  menu: (provided) => ({
    ...provided,
    zIndex: 10000
  }),
  container: (provided) => ({
    ...provided,
    fontFamily: 'Lato, sans-serif',
    top: '1px',
    left: '1px',
    maxWidth: '100%',
    width: '100%'
  }),
  option: (provided, state) => ({
    ...provided,
    background: state.isSelected ? 'var(--main-green)' : 'white',
    cursor: 'pointer'
  }),
  control: (provided, state) => ({
    ...provided,
    minHeight: '50px',
    border: state.isFocused ? '1px solid var(--main-green)' : '1px solid var(--main-gray-2)',
    '&:hover': {
      border: state.isFocused ? '1px solid var(--main-green)' : '1px solid var(--main-gray-2)'
    },
    boxShadow: 'none',
    borderRadius: '6px'
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none'
  })
};

export const selectCustomStyleSmall = {
  container: (provided) => ({
    ...provided,
    fontFamily: 'Lato, sans-serif',
    top: '1px',
    left: '1px',
    maxWidth: '100%',
    width: '100%'
  }),
  option: (provided, state) => ({
    ...provided,
    background: state.isSelected ? 'var(--main-green)' : 'white',
    cursor: 'pointer'
  }),
  control: (provided, state) => ({
    ...provided,
    minHeight: '30px',
    border: state.isFocused ? '1px solid var(--main-green)' : '1px solid #DCDCDC',
    '&:hover': {
      border: state.isFocused ? '1px solid var(--main-green)' : '1px solid #DCDCDC'
    },
    boxShadow: 'none'
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none'
  })
};

export const selectCustomStyleCategory = {
  container: (provided) => ({
    ...provided,
    fontFamily: 'Lato, sans-serif',
    top: '1px',
    left: '1px',
    maxWidth: '100%',
    width: '120px',
    border: 'none'
  }),
  option: (provided, state) => ({
    ...provided,
    background: state.isSelected ? 'var(--main-green)' : 'white',
    cursor: 'pointer'
  }),
  control: (provided, state) => ({
    ...provided,
    minHeight: '30px',
    border: 'none',
    background: '#F4F4F4',
    boxShadow: 'none',
    paddingLeft: '20px'
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none'
  })
};


export const GlobalSelect = ({
  options,
  onChange,
  styles = selectCustomStyle,
  dropdownIndicator = customDropdownIndicator,
  value,
  placeholder,
  onSelectCategory
}) => {
  return (
    <Select
      onSelectCategory={onSelectCategory}
      options={options}
      onChange={onChange}
      styles={styles}
      components={{ DropdownIndicator: dropdownIndicator }}
      placeholder={placeholder}
      value={value}
    />
  );
};