import React, { useState } from 'react';
import {
    Content,
    SidebarTitle,
    SecondSection,
    TextInfo,
    Social,
    HorizontalLine,
    SecondDropdownWrapper,
    ChangeStatus,
    BottomTotal,
    TotalAmount
} from './all-order-styled';
import Dropdown from '../../../company-page/components/products/dropdown/dropdown';
import whatsappIcon from '../../../../assets/whatsapp.svg';
import phoneIcon from '../../../../assets/phone_white.svg';
import { TableWrapper, Styles } from 'components/table/orderProductTable';
import { useDispatch, useSelector } from 'react-redux';
import { DropdownsWrapper } from './all-order-styled';
import { useEffect } from 'react';
import { GlobalSelect } from 'components/global-select/global-select';
import { GlobalButton } from 'components/global-button/global-button';
import ColourRingLoader from 'components/loaders/colour-ring-loader/ColourRingLoader';
import { SvgIcon } from 'components/svg-icon/svg-icon';
import { updateOrder } from '../../../../redux/slices/orders/ordersSlice';
const OrderCreatedDetailsWidget = ({ orderData, orderBID, scrollToTop, elementRef }) => {
    const updateStatusGlobal = useSelector((state) => state.orders.updateStatus);
    const singleOrderStatus = useSelector((state) => state.orders.singleOrderStatus);
    const [updateStatus, setUpdateStatus] = useState(null);
    const dispatch = useDispatch();
    const [orderStatus, setOrderStatus] = useState(null);
    const { customer, orderDetails, billingAddress, deliveryDetails, products, orderValue } =
        orderData;
    const [newStatus, setNewStatus] = useState();

    const statusOptions = [
        { value: 'completed', label: 'Completed' },
        { value: 'pending', label: 'Awaiting Payment' },
        { value: 'on-hold', label: 'On hold' },
        { value: 'processing', label: 'Awaiting fulfillment' },
        { value: 'cancelled', label: 'Cancelled' }
    ];
    useEffect(() => {
        setOrderStatus(singleOrderStatus);
    }, [singleOrderStatus]);
    useEffect(() => {
        setNewStatus(orderDetails.status);
    }, [orderDetails.status]);

    const changeStatus = () => {
        setUpdateStatus('loading');
        const params = {
            bID: orderBID,
            orderID: orderData.id,
            orderStatus: newStatus
        };
        dispatch(updateOrder(params)).then((result) => {
            setMessage(result);
            if (result.payload.message === 'success') {
                setChangeText('Updated!');
                setUpdateStatus('success');
            }
        });
    };

    const [message, setMessage] = useState();
    const [changeText, setChangeText] = useState('Change Status');
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            height: 20,
            boxShadow: 'none',
            borderColor: 'none',
            '&:hover': {
                color: 'green'
            },
            border: state.isFocused ? '1.5px solid #DCDCDC' : '1.5px solid #DCDCDC',
            whiteSpace: 'nowrap',
            width: '200px',
            marginLeft: '-50px'
        }),
        option: (styles, state) => ({
            ...styles,
            color: state.isSelected ? '#FFF' : styles.color,
            backgroundColor: state.isSelected ? 'green' : styles.color,
            borderBottom: '1px solid rgba(0, 0, 0, 0.125)',
            whiteSpace: 'nowrap',
            '&:hover': {
                color: '#FFF',
                backgroundColor: 'green'
            }
        }),
        input: (base) => ({
            ...base,
            'input:focus': {
                boxShadow: 'none',
                border: '1px solid green'
            }
        }),
        menuPortal: (base) => ({ ...base, zIndex: 9999 })
    };

    return (
        <>
            <Content ref={elementRef}>
                <SecondSection>
                    <SidebarTitle>Customer Details</SidebarTitle>
                    <TextInfo>
                        <p>Name</p>
                        <h5>{customer.name}</h5>
                    </TextInfo>
                    <TextInfo>
                        <p>Phone Number</p>
                        <h5>{customer.phone}</h5>
                    </TextInfo>
                    <TextInfo>
                        <p>Email</p>
                        <h5>{customer.email}</h5>
                    </TextInfo>
                    <Social>
                        <div
                            className='whatsapp'
                            onClick={() =>
                                window.open(`https://wa.me/${customer.whatsApp}`, '_blank')
                            }
                        >
                            <img src={whatsappIcon} alt='' />
                        </div>
                        <div
                            className='phone'
                            onClick={() => window.open(`tel:${customer.phone}`, '_blank')}
                        >
                            <img src={phoneIcon} alt='' />
                        </div>
                    </Social>
                    <HorizontalLine />
                    <SidebarTitle>Billing Address</SidebarTitle>
                    <TextInfo>
                        <p>Street Address</p>
                        <h5>{billingAddress.street}</h5>
                    </TextInfo>
                    <TextInfo>
                        <p>Suburb</p>
                        <h5>{billingAddress.suburb}</h5>
                    </TextInfo>
                    <TextInfo>
                        <p>City</p>
                        <h5>{billingAddress.city}</h5>
                    </TextInfo>
                    <TextInfo>
                        <p>Country</p>
                        <h5>{billingAddress.country}</h5>
                    </TextInfo>
                    <HorizontalLine />
                    <SidebarTitle>Order Details</SidebarTitle>

                    <TextInfo>
                        <p>Payment Method</p>
                        <h5>{orderDetails.paymentMethod}</h5>
                    </TextInfo>
                    <TextInfo>
                        <p>Delivery Address</p>
                        <h5>
                            {`${deliveryDetails.address.street}, ` +
                                `${deliveryDetails.address.suburb}, ` +
                                `${deliveryDetails.address.city}`}
                        </h5>
                    </TextInfo>
                    <HorizontalLine />
                    <SidebarTitle>Order Status</SidebarTitle>
                    <TextInfo>
                        <p>Order Status</p>
                        <DropdownsWrapper>
                            <GlobalSelect
                                options={statusOptions}
                                //value={selectedOrder.orderDetails.status}
                                placeholder={
                                    statusOptions.find((option) => option.value === newStatus)
                                        ?.label
                                }
                                onChange={(selectedOption) => {
                                    const newStatusFilter = selectedOption.value;
                                    console.log(newStatusFilter);
                                    setMessage('Changed status');
                                    setNewStatus(newStatusFilter);
                                }}
                                styles={customStyles}
                            />
                            <GlobalButton type={'primary'} onClick={changeStatus}>
                                {updateStatus === 'loading' && (
                                    <ColourRingLoader width={25} height={25} />
                                )}
                                {updateStatus === 'success' && <SvgIcon name={'check-square'} />}

                                {changeText}
                            </GlobalButton>
                        </DropdownsWrapper>
                    </TextInfo>

                    <HorizontalLine />
                    {deliveryDetails.instructions ? (
                        <>
                            <SidebarTitle>Customer Notes</SidebarTitle>

                            <TextInfo>
                                <p>{deliveryDetails.instructions}</p>
                            </TextInfo>

                            <HorizontalLine />
                        </>
                    ) : (
                        ''
                    )}

                    <SidebarTitle>Ordered Products</SidebarTitle>
                    <TableWrapper>
                        <Styles>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Image</th>
                                        <th>Product Name</th>
                                        <th>Quantity</th>
                                        <th>Price</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {products.length === 0 ? (
                                        <tr></tr>
                                    ) : (
                                        products.map((item) => (
                                            <tr key={item.id}>
                                                <td>
                                                    <img
                                                        src={item.image}
                                                        style={{
                                                            width: '50px',
                                                            height: '50px',
                                                            objectFit: 'cover'
                                                        }}
                                                    />
                                                </td>
                                                <td>{item.name}</td>
                                                <td>{item.quantity}</td>
                                                <td>
                                                    <td>${item.price}</td>
                                                </td>
                                                <td>${item.total}</td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                            </table>
                        </Styles>
                    </TableWrapper>
                    {/* <Table
                    columnsData={SecondSidebarColumnsData}
                    columnsRows={SecondSidebarColumnsRows}
                    minWidth={'640px'}
                /> */}
                    <BottomTotal>
                        <TotalAmount>Total Amount</TotalAmount>
                        <SidebarTitle>${orderDetails.total}</SidebarTitle>
                    </BottomTotal>
                </SecondSection>
            </Content>
        </>
    );
};

export default OrderCreatedDetailsWidget;
