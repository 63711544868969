import { FacebookIcon2 } from './icons/FacebookIcon';
import { AccountIcon } from './icons/account-icon';
import { AlertIcon } from './icons/alert-icon';
import { AnnotationCheck } from './icons/annotation-check';
import { ArrowSave } from './icons/arrow-save';
import { ArrowUpIcon } from './icons/arrow-up-icon';
import { BellIcon } from './icons/bell-icon';
import { BooksIcon } from './icons/books-icon';
import { BuildingIcon } from './icons/building-icon';
import { BurgerIcon } from './icons/burger-icon';
import { Call } from './icons/call';
import { CartIcon } from './icons/cart-icon';
import { CheckIcon } from './icons/check-icon';
import { CheckSquare } from './icons/check-square';
import { ChevronLeft } from './icons/chevron-left';
import { ClockIcon } from './icons/clock-icon';
import { ClothingIcon } from './icons/clothing-icon';
import { CoinsHand } from './icons/coins-hand';
import { CommentIco } from './icons/comment-ico';
import { ConstructionIcon } from './icons/construction-icon';
import { CopyIcon } from './icons/copy-icon';
import { Delete } from './icons/delete';
import { DownloadIcon } from './icons/download-icon';
import { ElectronicIcon } from './icons/electronic-icon';
import { EmailIcon } from './icons/email-icon';
import { EstateIcon } from './icons/estate-icon';
import { EyeIcon } from './icons/eye-icon';
import { FacebookIcon } from './icons/facebook-icon';
import { FarmingIcon } from './icons/farming-icon';
import { FilterIcon } from './icons/filter-icon';
import { FoodIcon } from './icons/food-icon';
import { GiftIcon } from './icons/gift-icon';
import { GoogleIcon } from './icons/google-icon';
import { HeartIcon } from './icons/heart-icon';
import { HeartIcon2 } from './icons/heart-icon-2';
import { HomeIcon } from './icons/home-icon';
import { IndustrialIcon } from './icons/industrial-icon';
import { InstagramIcon } from './icons/instagram-icon';
import { LabelIcon } from './icons/label-icon';
import { LinkedinIcon } from './icons/linkedin-icon';
import { MailIcon } from './icons/mail-icon';
import { MarkerPin } from './icons/marker-pin';
import { MessageCheckSquare } from './icons/message-check-square';
import { MessageTextSquare } from './icons/message-text-square';
import { MessegeQuestionSquare } from './icons/messege-question-square';
import { MotorIcon } from './icons/motor-icon';
import { MsgIcon } from './icons/msg-icon';
import { NotificationsTextIcon } from './icons/notifications-text-icon';
import { PercentIcon } from './icons/percent-icon';
import { PhoneIcon } from './icons/phone-icon';
import { Plus } from './icons/plus';
import { PlusIcon } from './icons/plus-icon';
import { Point } from './icons/point';
import { ReceiptCheckIcon } from './icons/receipt-check-icon';
import { Request } from './icons/request';
import { SearchIcon } from './icons/search-icon';
import { ShareIcon } from './icons/share-icon';
import { SliderArrow } from './icons/slider-arrow';
import { SortIcon } from './icons/sort-icon';
import { SportsIcon } from './icons/sports-icon';
import { TiktokIcon } from './icons/tiktok-icon';
import { Track } from './icons/track';
import { TradesIcon } from './icons/trades-icon';
import { TwitterIcon } from './icons/twitter-icon';
import { UploadIcon } from './icons/upload-icon';
import { VehiclesIcon } from './icons/vehicles-icon';
import { WalletIcon } from './icons/wallet-icon';
import { WhatsupIcon } from './icons/whatsup-icon';
import { YoutubeIcon } from './icons/youtube-icon';

export const SvgIcon = ({ name }) => {
  switch (name) {
    case 'arrow-up':
      return <ArrowUpIcon />;
    case 'mail':
      return <MailIcon />;
    case 'label':
      return <LabelIcon />;
    case 'msg':
      return <MsgIcon />;
    case 'books':
      return <BooksIcon />;
    case 'clothing':
      return <ClothingIcon />;
    case 'construction':
      return <ConstructionIcon />;
    case 'electronic':
      return <ElectronicIcon />;
    case 'farming':
      return <FarmingIcon />;
    case 'food':
      return <FoodIcon />;
    case 'health':
      return <HeartIcon />;
    case 'home':
      return <HomeIcon />;
    case 'industrial':
      return <IndustrialIcon />;
    case 'motor':
      return <MotorIcon />;
    case 'vehicles':
      return <VehiclesIcon />;
    case 'estate':
      return <EstateIcon />;
    case 'sport':
      return <SportsIcon />;
    case 'trades':
      return <TradesIcon />;
    case 'cart':
      return <CartIcon />;
    case 'heart-2':
      return <HeartIcon2 />;
    case 'eye':
      return <EyeIcon />;
    case 'whatsup':
      return <WhatsupIcon />;
    case 'phone':
      return <PhoneIcon />;
    case 'call':
      return <Call />;
    case 'email':
      return <EmailIcon />;
    case 'account':
      return <AccountIcon />;
    case 'google':
      return <GoogleIcon />;
    case 'facebook':

      return <FacebookIcon2 />;
    case 'burger':
      return <BurgerIcon />;
    case 'filter':
      return <FilterIcon />;
    case 'chevron-left':
      return <ChevronLeft />;
    case 'search':
      return <SearchIcon />;
    case 'sort':
      return <SortIcon />;
    case 'percent':
      return <PercentIcon />;
    case 'marker-pin':
      return <MarkerPin />;
    case 'slider-arrow':
      return <SliderArrow />;
    case 'wallet':
      return <WalletIcon />;
    case 'share':
      return <ShareIcon />;
    case 'alert':
      return <AlertIcon />;
    case 'message-check-square':
      return <MessageCheckSquare />;
    case 'message-text-square':
      return <MessageTextSquare />;
    case 'coins-hand':
      return <CoinsHand />;
    case 'clock':
      return <ClockIcon />;
    case 'check':
      return <CheckIcon />;
    case 'copy':
      return <CopyIcon />;
    case 'gift':
      return <GiftIcon />;
    case 'point':
      return <Point />;
    case 'download':
      return <DownloadIcon />;
    case 'instagram':
      return <InstagramIcon />;
    case 'youtube':
      return <YoutubeIcon />;
    case 'twitter':
      return <TwitterIcon />;
    case 'linkedin':
      return <LinkedinIcon />;
    case 'tiktok':
      return <TiktokIcon />;
    case 'facebook-2':
      return <FacebookIcon />;
    case 'receipt':
      return <ReceiptCheckIcon />;
    case 'upload':
      return <UploadIcon />;
    case 'plus':
      return <PlusIcon />;
    case 'track':
      return <Track />;
    case 'comment-ico':
      return <CommentIco />;
    case 'request':
      return <Request />;
    case 'plus-second':
      return <Plus />;
    case 'check-square':
      return <CheckSquare />;
    case 'save':
      return <ArrowSave />;
    case 'delete':
      return <Delete />;
    case 'notifications-text':
      return <NotificationsTextIcon />;
    case 'bell':
      return <BellIcon />;
    case 'annotation-check':
      return <AnnotationCheck />;
    case 'building':
      return <BuildingIcon />;
    case 'message-question-square':
      return <MessegeQuestionSquare />;
    default:
      return <></>;
  }
};
