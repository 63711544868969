import styled, { css } from 'styled-components';
import { title30 } from 'assets/style-helpers/typography';
import { buttonReset, LatoFont } from 'assets/style-helpers/style-helpers';
import clockIcon from './img/clock-icon.svg';
import clockIconDisabled from './img/clock-icon-disabled.svg';
import { device } from '../../../../assets/style-helpers/breakpoints';

export const Container = styled.div``;

export const Title = styled.div`
  ${title30};
  margin-bottom: 20px;
`;

export const Wrapper = styled.div`
  display: flex;
  margin-bottom: 106px;
`;

export const Main = styled.div`
  flex-basis: 700px;
  margin-right: 32px;
  @media ${device.laptop} {
    flex-basis: auto;
    width: 100%;
    margin-right: 0;
  }
`;

export const Side = styled.div`
  flex-basis: calc(100% - 32px - 700px);
  @media ${device.laptop} {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 10000;
    overflow-y: auto;
    transition: all 0.3s;
    ${(props) =>
    props.isSidebarHidden
      ? css`
            opacity: 0;
            pointer-events: none;
            transition-delay: 0.3s;
          `
      : css`
            opacity: 1;
            pointer-events: all;
          `};
  }
`;

export const TagsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Checkboxes = styled.div`
  display: flex;
`;

export const MapWrapper = styled.div`
  height: 240px;
  width: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const RadioWrapper = styled.div`
  margin-bottom: 16px;
`;

export const WorkingHoursForm = styled.div`
  max-width: 560px;
`;

export const WorkingHoursCell = styled.div`
  display: flex;
  align-items: center;
`;

export const GreenText = styled.div`
  ${LatoFont};
  color: var(--main-green);
`;

export const WorkingHoursRow = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 6px 0;
`;

export const HourInput = styled.input`
  border-radius: 6px;
  border: 1px solid var(--divider);
  padding: 6px 12px 6px 36px;
  ${LatoFont};
  max-width: 90px;
  
  background-size: 18px 18px;
  &:disabled {
    background: url(${clockIconDisabled}) no-repeat 10px 5px;
    color: var(--disabled-text);
  }
`;
export const TimeInput = styled.input`
border-radius: 6px;
  border: 1px solid var(--divider);
  padding: 6px 12px 6px 36px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 56px;
  button + button {
    margin-left: 20px;
  }

  @media ${device.tablet} {
    justify-content: space-between;
  }

  @media ${device.mobile} {
    flex-direction: column;
    button {
      width: 100%;
    }

    button + button {
      margin-left: 0;
      margin-top: 16px;
    }
  }
`;

export const SidebarBackground = styled.div`
  display: none;
  @media ${device.laptop} {
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(31, 49, 36, 0.4);
    top: 0;
    left: 0;
    z-index: 1000;
  }
`;

export const OpenSidebarBtn = styled.button`
  display: none;
  @media ${device.laptop} {
    display: flex;
  }
  @media ${device.tablet} {
    display: none;
  }
  ${buttonReset};
  position: fixed;
  right: -82px;
  top: 610px;
  z-index: 1001;
  padding: 0 20px;
  align-items: center;
  justify-content: center;
  transform: rotate(-90deg);
  cursor: pointer;
  height: 40px;
  background: #ebf3eb;
  border-radius: 6px 6px 0 0;
  color: var(--main-green);
  transition: all 0.3s;
  ${(props) =>
    props.isSidebarHidden
      ? css`
          transform: translateX(0) rotate(-90deg);
        `
      : css`
          transform: translateX(-670px) rotate(-90deg);
          opacity: 0;
          pointer-events: none;
        `}
  svg {
    margin-right: 8px;
  }
`;

export const OpenSidebarBtnSidebar = styled.button`
  display: none;
  @media ${device.laptop} {
    display: flex;
  }
  @media ${device.tablet} {
    display: none;
  }
  ${buttonReset};
  position: fixed;
  right: -97px;
  top: 340px;
  z-index: 10000;
  padding: 0 20px;
  align-items: center;
  justify-content: center;
  transform: rotate(-90deg);
  cursor: pointer;
  height: 40px;
  background: #ebf3eb;
  border-radius: 6px 6px 0 0;
  color: var(--main-green);
  transition: all 0.3s;
  ${(props) =>
    props.isSidebarHidden
      ? css`
          transform: translateX(0) rotate(-90deg);
          opacity: 0;
          pointer-events: none;
        `
      : css`
          transform: translateX(-670px) rotate(-90deg);
        `}
  svg {
    margin-right: 8px;
  }
`;

export const MobileOpenPreviewBtnWrapper = styled.div`
  display: none;
  @media ${device.tablet} {
    display: block;
  }

  @media ${device.mobile} {
    margin-top: 20px;
    width: 100%;
  }
`;
