import { Link } from 'react-router-dom';

import { AuthPagesContainer } from 'components/auth-pages-container/auth-pages-container';
import { GlobalCheckbox } from 'components/global-checkbox/global-checkbox';
import { GlobalInputPassword } from 'components/global-input-password/global-input-password';
import { GlobalInput } from 'components/global-input/global-input';
import { GoogleFacebookAuth } from 'components/google-facebook-auth/google-facebook-auth';
import Header from 'components/header/header';
import { InputGroup } from 'components/input-group/input-group';
import { SvgIcon } from 'components/svg-icon/svg-icon';
import {
    ChecbkoxLabel,
    FormBotton,
    GrayText,
    InputGroupWrapper,
    LoginBtn,
    LoginForm,
    StyledLink
} from './login-styled';
// import { useAuth } from '../../context/auth-context';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { login } from '../../redux/slices/auth/authSlice';
import { Alert } from 'pages/dashboard/components/alert/alert';
import ColourRingLoader from 'components/loaders/colour-ring-loader/ColourRingLoader';
import ThreeDotsLoader from 'components/loaders/three-dots-loader/ThreeDotsLoader';
import { jwtDecode } from 'jwt-decode';
import { getRouteById } from 'routes/routesHelper';
import { set } from 'lodash';

export const Login = () => {

    const isAuth = useSelector((state) => state.auth);
    const [err, setErr] = useState(null);
    const [loading, setLoading] = useState('idle');

    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem('user'));
 
    useEffect(() => {
        if (isAuth.isAuth) {
            navigate('/dashboard');
        }
        isAuth.status === 'loading' ? setLoading('loading') : setLoading('idle');
    }, [isAuth, navigate]);
    
    const [username, setUsername ] = useState('');
    const [password, setPassword ] = useState('');

    const dispatch = useDispatch();

    const handleLogin = (e) => {
        const credentials = {
            username : username,
            password : password
        }
        e.preventDefault();
        dispatch( login(credentials) ).then((result) => {
            if (result.error.message === 'Rejected') {
                setErr(result.payload);
            }
        }); 
        setLoading(isAuth.loading);
        setErr(isAuth.error);
    }
    
    return (
        <>
       
        <Header />
        <AuthPagesContainer title={'Login'}>
             { loading == 'loading'
                ? 
                    <ColourRingLoader/>
                :   
                    isAuth.isAuth 
                    ?
                    <>
                        <ColourRingLoader/>
                        <Alert type={'success'}>Welcome, {user.firstName}... We're loggin you in now. </Alert>
                    </>
                :
                    err && <Alert type={'error'}>{isAuth.error}</Alert>
            }<br/>
                
            <LoginForm>
                { loading !== 'loading' ?
                    <>
                    <InputGroupWrapper>
                        <InputGroup label={'E-mail or Username'}>
                            <GlobalInput placeholder={'Your email address'} 
                                onChange={(e) => setUsername(e.target.value)}
                                value={username}
                            />
                        </InputGroup>
                    </InputGroupWrapper>
                    <InputGroupWrapper>
                        <InputGroup label={'Password'}>
                            <GlobalInputPassword 
                                onChange={(e) => setPassword(e.target.value)}
                                value={password}
                            />
                        </InputGroup>
                    </InputGroupWrapper>
                    <LoginBtn
                        height={52}
                        fullWidth
                        onClick={handleLogin}
                    >   
                    { loading == 'loading'
                        ?
                            <ThreeDotsLoader/>
                        :
                        <>
                            <SvgIcon name={'account'} />
                            Login
                        </>
                    }
                    </LoginBtn>
                    <FormBotton>
                        <GlobalCheckbox isChecked={true}>
                        <ChecbkoxLabel>Remember me</ChecbkoxLabel>
                        </GlobalCheckbox>
                        <StyledLink>
                        <Link to={ getRouteById('passwordReset') }>Forgot password?</Link>
                        </StyledLink>
                    </FormBotton>
                    <GoogleFacebookAuth description={'Or login with'}>
                        <GrayText>Don't have an account yet?</GrayText>
                        <StyledLink>
                        <Link to={ getRouteById('register') }>Sign up</Link>
                        </StyledLink>
                    </GoogleFacebookAuth>
                    </>
                : null
            }
            </LoginForm>
        </AuthPagesContainer>
        </>
    );
};
