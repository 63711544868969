import { StyledInput } from './global-input-styled';

export const GlobalInput = ({ value, onChange, placeholder, type, name, width }) => {
  return (
    <StyledInput
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      type={type}
      name={name}
      width={width}
      required
    />
  );
};
