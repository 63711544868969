

import React, { useEffect, useState } from 'react';
import {
    Content,
    SidebarTitle,
    SecondSection,
    TextInfo,
    Social,
    HorizontalLine,
    SecondDropdownWrapper,
    ChangeStatus,
    BottomTotal,
    TotalAmount
} from './all-order-styled';
import Dropdown from '../../../company-page/components/products/dropdown/dropdown';
;
import whatsappIcon from '../../../../assets/whatsapp.svg';
import phoneIcon from '../../../../assets/phone_white.svg';
import { TableWrapper, Styles } from './orderProductTable';
import { DropdownsWrapper } from './all-order-styled';
import { GlobalSelect } from 'components/global-select/global-select';
import { useDispatch } from 'react-redux';
import { updateOrder } from '../../../../redux/slices/orders/ordersSlice';
import { result, set } from 'lodash';
import { useSelector } from 'react-redux';
import { getOrder } from '../../../../redux/slices/orders/ordersSlice';
import ColourRingLoader from 'components/loaders/colour-ring-loader/ColourRingLoader';
import { GlobalButton } from 'components/global-button/global-button';
import { SvgIcon } from 'components/svg-icon/svg-icon';

const OrderDetailsWidget = ({ orderData, orderId, bID, setRefreshOrders }) => {


    const singleOrderStatus = useSelector(state => state.orders.singleOrderStatus);
    const updateStatusGlobal = useSelector(state => state.orders.updateStatus);
    const [orderStatus, setOrderStatus] = useState(null);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [updateStatus, setUpdateStatus] = useState(null);
    const dispatch = useDispatch();


    useEffect(() => {
        const params = {
            bID: bID,
            orderID: orderId
        }
        dispatch(getOrder(params)).then((result) => {
            console.log('Order:', result)
            setSelectedOrder(result.payload.data.order);
            setNewStatus(result.payload.data.order.orderDetails.status);
        });
    }, []);

    const statusOptions = [
        { value: 'completed', label: 'Completed' },
        { value: 'pending', label: 'Awaiting Payment' },
        { value: 'on-hold', label: 'On hold' },
        { value: 'processing', label: 'Awaiting fulfillment' },
        { value: 'cancelled', label: 'Cancelled' },
    ];

    const [newStatus, setNewStatus] = useState();
    const [message, setMessage] = useState();
    const [changeText, setChangeText] = useState('Change Status');

    useEffect(() => {
        setOrderStatus(singleOrderStatus);
    }, [singleOrderStatus]);



    const changeStatus = () => {
        setUpdateStatus('loading');
        const params = {
            bID: bID,
            orderID: selectedOrder.id,
            orderStatus: newStatus
        }
        dispatch(updateOrder(params)).then((result) => {
            setMessage(result);
            if (result.payload.message === 'success') {
                setChangeText('Updated!');
                setUpdateStatus('success');
                setRefreshOrders(true);

            }

        });

    }

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            height: 30,
            boxShadow: 'none',
            borderColor: 'none',
            '&:hover': {
                color: 'green'
            },
            border: state.isFocused ? '1.5px solid #DCDCDC' : '1.5px solid #DCDCDC',
            width: '200px',
            marginLeft: '-50px',

        }),
        option: (styles, state) => ({
            ...styles,
            color: state.isSelected ? '#FFF' : styles.color,
            backgroundColor: state.isSelected ? 'green' : styles.color,
            borderBottom: '1px solid rgba(0, 0, 0, 0.125)',
            '&:hover': {
                color: '#FFF',
                backgroundColor: 'green'
            }
        }),
        input: (base) => ({
            ...base,
            'input:focus': {
                boxShadow: 'none',
                border: '1px solid green',

            }
        }),
        menuPortal: (base) => ({ ...base, zIndex: 9999 })
    }

    return <>

        <Content>
            {singleOrderStatus === 'loading' ?
                <>
                    <div style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}>
                        <ColourRingLoader />
                    </div>
                </>
                : selectedOrder ? (<SecondSection>
                    <SidebarTitle>Customer Details</SidebarTitle>
                    <TextInfo>
                        <p>Name</p>
                        <h5>{selectedOrder.customer.name}</h5>
                    </TextInfo>
                    <TextInfo>
                        <p>Phone Number</p>
                        <h5>{selectedOrder.customer.phone}</h5>
                    </TextInfo>
                    <TextInfo>
                        <p>Email</p>
                        <h5>{selectedOrder.customer.email}</h5>
                    </TextInfo>
                    <Social>
                        <div className='whatsapp' onClick={() => window.open(`https://wa.me/${selectedOrder.customer.whatsapp}`, "_blank")}>
                            <img src={whatsappIcon} alt='' />
                        </div>
                        <div className='phone' onClick={() => window.open(`tel:${selectedOrder.customer.phone}`, "_blank")} >
                            <img src={phoneIcon} alt='' />
                        </div>
                    </Social>
                    <HorizontalLine />
                    <SidebarTitle>Billing Address</SidebarTitle>
                    <TextInfo>
                        <p>Street Address</p>
                        <h5>{selectedOrder.billingAddress && selectedOrder.billingAddress.street ? selectedOrder.billingAddress.street : ''}</h5>

                    </TextInfo>
                    <TextInfo>
                        <p>Suburb</p>
                        <h5>{selectedOrder.billingAddress && selectedOrder.billingAddress.suburb ? selectedOrder.billingAddress.suburb : ''}</h5>

                    </TextInfo>
                    <TextInfo>
                        <p>City</p>
                        <h5>{selectedOrder.billingAddress && selectedOrder.billingAddress.city ? selectedOrder.billingAddress.city : ''}</h5>
                    </TextInfo>
                    <TextInfo>
                        <p>Country</p>
                        <h5>{selectedOrder.billingAddress && selectedOrder.billingAddress.country ? selectedOrder.billingAddress.country : ''}</h5>
                    </TextInfo>
                    <HorizontalLine />
                    <SidebarTitle>Order Details</SidebarTitle>
                    <TextInfo>
                        <p>Currency</p>
                        <h5>{selectedOrder.orderDetails.currency}</h5>
                    </TextInfo>
                    <TextInfo>
                        <p>Amount</p>
                        <h5>${selectedOrder.orderDetails.total}</h5>
                    </TextInfo>
                    <TextInfo>
                        <p>Payment Method</p>
                        <h5>{selectedOrder.orderDetails.paymentMethod}</h5>
                    </TextInfo>
                    <TextInfo>
                        <p>Delivery Address</p>
                        <h5>{selectedOrder.deliveryDetails.address.street}</h5>
                    </TextInfo>
                    <HorizontalLine />
                    <SidebarTitle>Order Status</SidebarTitle>
                    <TextInfo>
                        <p>Order Status</p>
                        <DropdownsWrapper>
                            <GlobalSelect
                                options={statusOptions}
                                //value={selectedOrder.orderDetails.status}
                                placeholder={statusOptions.find(option => option.value === newStatus)?.label}
                                onChange={(selectedOption) => {
                                    const newStatusFilter = selectedOption.value;
                                    console.log(newStatusFilter)
                                    setMessage('Changed status');
                                    setNewStatus(newStatusFilter);
                                }}
                                styles={customStyles}
                            />
                            <GlobalButton type={'primary'} onClick={changeStatus}>
                                {updateStatus === 'loading' && <ColourRingLoader width={25} height={25} />}
                                {updateStatus === 'success' && <SvgIcon name={'check-square'} />}

                                {changeText}

                            </GlobalButton>

                        </DropdownsWrapper>

                    </TextInfo>

                    <HorizontalLine />
                    {selectedOrder.deliveryDetails.instructions ? <>
                        <SidebarTitle>Customer Notes</SidebarTitle>

                        <TextInfo>
                            <p>{selectedOrder.deliveryDetails.instructions}</p>
                        </TextInfo>


                        <HorizontalLine />
                    </> : ''}


                    <SidebarTitle>Order Products</SidebarTitle>
                    <TableWrapper>
                        <Styles>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Image</th>
                                        <th>Product Name</th>
                                        <th>Quantity</th>
                                        <th>Price</th>
                                        <th>Total</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedOrder.products.length === 0 ? <tr ></tr> : (selectedOrder.products.map((item) => (
                                        <tr key={item.id}>
                                            <td><img src={item.image} style={{ width: '50px', height: '50px', objectFit: 'cover' }} /></td>
                                            <td>{item.name}</td>
                                            <td>
                                                {item.quantity}
                                            </td>
                                            <td>
                                                <td>${item.price}</td>
                                            </td>
                                            <td>${item.total}</td>

                                        </tr>
                                    )))}

                                </tbody>
                            </table>
                        </Styles>
                    </TableWrapper>

                    <BottomTotal>
                        <TotalAmount>Total Amount</TotalAmount>
                        <SidebarTitle>${selectedOrder.orderDetails.total}</SidebarTitle>
                    </BottomTotal>
                </SecondSection>) : ('Loading')}

        </Content>
    </>
}

export default OrderDetailsWidget;