import { ReactComponent as CloseIcon } from 'pages/dashboard/img/close-icon.svg';
import { ReactComponent as AllAppointments } from '../../../../../assets/icons/all-appointments.svg';
import { ReactComponent as AllSellItems } from '../../../../../assets/icons/all-sell-Items.svg';
import avatar from '../../../../../assets/icons/avatar3.png';
import { ReactComponent as BulkUpload } from '../../../../../assets/icons/bulk-upload.svg';
import { ReactComponent as BusinessList } from '../../../../../assets/icons/business-list.svg';
import { ReactComponent as BusinessList2 } from '../../../../../assets/icons/business-list2.svg';
import { ReactComponent as ExitIco } from '../../../../../assets/icons/exit.svg';
import { ReactComponent as FavoriteProducts } from '../../../../../assets/icons/favorite-products.svg';
import { ReactComponent as Favorites } from '../../../../../assets/icons/favorites.svg';
import { ReactComponent as Inbox } from '../../../../../assets/icons/inbox.svg';
import { ReactComponent as Messages } from '../../../../../assets/icons/messages.svg';
import { ReactComponent as MyAccount } from '../../../../../assets/icons/my-account.svg';
import { ReactComponent as MyAppointments } from '../../../../../assets/icons/my-appointments.svg';
import { ReactComponent as MyCalendar } from '../../../../../assets/icons/my-calendar.svg';
import { ReactComponent as NotificationsIco } from '../../../../../assets/icons/notifications.svg';
import { ReactComponent as Orders } from '../../../../../assets/icons/orders.svg';
import { ReactComponent as MyOrders } from '../../../../../assets/icons/orders2.svg';
import { ReactComponent as SellAnItem } from '../../../../../assets/icons/sell-an-Item.svg';
import { ReactComponent as Selling } from '../../../../../assets/icons/selling.svg';
import { ReactComponent as AccountSettings } from '../../../../../assets/icons/setting.svg';

import { GlobalButton } from 'components/global-button/global-button';
import {
  Avatar,
  name,
  CloseDashboardBtnWrapper,
  Container,
  Exit,
  Img,
  MenuItem,
  MenuTitle,
  MenuWrapper,
  Notifications,
  Submenu,
  SubmenuLink,
  User,
  UserInfo,
  UserMain
} from './menu-styled.jsx';

const menu = [
  {
    name: 'My Account',
    icon: <MyAccount />,
    submenu: [
      {
        name: 'Account Settings',
        icon: <AccountSettings />,
        link: 'profile'
      }
    ]
  },
  {
    name: 'Messages',
    icon: <Messages />,
    submenu: [
      {
        name: 'Notifications',
        icon: <NotificationsIco />,
        notifications: 25,
        link: 'messages'
      },
      {
        name: 'Inbox',
        icon: <Inbox />,
        notifications: 25,
        link: 'inbox'
      }
    ]
  },
  {
    name: 'Favorites',
    icon: <Favorites />,
    submenu: [
      {
        name: 'Favorite Products',
        icon: <FavoriteProducts />,
        link: 'favorites'
      }
    ]
  },
  {
    name: 'Orders',
    icon: <Orders />,
    submenu: [
      {
        name: 'My Orders',
        icon: <MyOrders />,
        link: 'my-orders',
        notifications: 15
      }
    ]
  },
  {
    name: 'Business List',
    icon: <BusinessList />,
    submenu: [
      {
        name: 'Business List',
        icon: <BusinessList2 />,
        link: 'business-list'
      }
    ]
  },
  {
    name: 'Selling',
    icon: <Selling />,
    submenu: [
      {
        name: 'Sell An Item',
        icon: <SellAnItem />,
        link: 'sell-an-item'
      },
      {
        name: 'All Sell Items',
        icon: <AllSellItems />,
        link: 'all-sell-items'
      },
      {
        name: 'Bulk Upload',
        icon: <BulkUpload />,
        link: 'bulk-upload'
      }
    ]
  },
  {
    name: 'My Appointments',
    icon: <MyAppointments />,
    submenu: [
      {
        name: 'My Calendar',
        icon: <MyCalendar />,
        link: 'my-calendar'
      },
      {
        name: 'All Appointments',
        icon: <AllAppointments />,
        link: 'all-appointments'
      }
    ]
  }
];

export const Sidebar = ({ onCloseBtnClick }) => {
  return (
    <Container>
      <CloseDashboardBtnWrapper>
        <GlobalButton type={'orange'} onClick={onCloseBtnClick}>
          <CloseIcon />
          Dashboard Menu
        </GlobalButton>
      </CloseDashboardBtnWrapper>
      <UserInfo>
        <User>
          <Avatar>
            <Img src={avatar} alt='' />
          </Avatar>
          <UserMain>
            <name>Langton Shoriwa</name>
          </UserMain>
        </User>
      </UserInfo>
      <MenuWrapper>
        {menu.map((item, i) => {
          return (
            <MenuItem key={i}>
              <MenuTitle>
                {item.icon}
                {item.name}
                {item.notifications && <Notifications>{item.notifications}</Notifications>}
              </MenuTitle>
              <Submenu>
                {item.submenu.map((item, i) => {
                  return (
                    <SubmenuLink to={item.link} key={i}>
                      {item.icon}
                      {item.name}
                      {item.notifications && <Notifications>{item.notifications}</Notifications>}
                    </SubmenuLink>
                  );
                })}
              </Submenu>
            </MenuItem>
          );
        })}
      </MenuWrapper>
      <Exit>
        <ExitIco />
        Log Out
      </Exit>
    </Container>
  );
};
