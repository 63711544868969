import React from 'react';
import './input-with-label.scss';

import search from '../../assets/search.svg';

const LinksInputWithLabel = ({
    value,
    onChange,
    wrapperClassName,
    inputClassName,
    width,
    height,
    placeholder,
    isLabel = true,
    name = ''
}) => {
    return (
        <section
            className={`${wrapperClassName} input-products`}
            style={{ width: width, height: height }}
        >
            {isLabel && (
                <div className='label' style={{ width: '25%' }}>
                    <p className='name'>{name}</p>
                </div>
            )}

            <input
                id={'input'}
                className={`${inputClassName} `}
                type='text'
                value={value}
                onChange={onChange}
                style={{}}
                placeholder={placeholder}
            ></input>

        </section>
    );
};

export default LinksInputWithLabel;
