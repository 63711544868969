import React, { useState, useEffect } from 'react';
import './price-selector.scss';
import { RangeSlider } from '../range-slider/range-slider';

// const PriceSelector = ({
//   min,
//   max,
//   onChange,
//   inputsTop = true,
// }) => {
//   const [selectorData, setSelectorData] = useState(values ? values : { min, max });
//   const [symbol, setSymbol] = useState('$');
//   const handleChange = (event, setState) => {
//     const value = Math.max(min, Math.min(max, Number(event.target.value)));
//     setState(value);
//   };

//   const handleRangeSliderChange = (data) => {
//     setSelectorData(data);
//     onChange(data);
//   };

//   // const handleChange = (event, setState) => {
//   //   const value = Math.max(min, Math.min(max, Number(event.target.value)));
//   //   setState(value);
//   // };

//   // const handleRangeSliderChange = (data) => {
//   //   setSelectorData(data);
//   // };

//   useEffect(() => {
//     if (values) {
//       setSelectorData(values);
//     }
//   }, [values]);
//   useEffect(() => {
//     if (km) {
//       setSymbol('km');
//     }
//     if (weight) {
//       setSymbol('kg');
//     }
//   }, [km, weight]);

//   return (
//     <div className='price-selection'>
//       {inputsTop ? (
//         <div className='price-inputs'>
//           <input
//             type='number'
//             value={selectorData.min}
//             onChange={(e) =>
//               handleChange(e, (value) => {
//                 setSelectorData({ ...selectorData, min: value });
//               })
//             }
//           />
//           <div className='horizontal-line' />
//           <input
//             type='number'
//             value={selectorData.max}
//             onChange={(e) =>
//               handleChange(e, (value) => {
//                 setSelectorData({ ...selectorData, max: value });
//               })
//             }
//           />
//         </div>
//       ) : (
//         ''
//       )}
//       <RangeSlider
//         defaultMin={defaultMin}
//         defaultMax={defaultMax}
//         min={min}
//         max={max}
//         minVal={selectorData.min}
//         maxVal={selectorData.max}
//         onChange={handleRangeSliderChange}
//       />
//       {!inputsTop ? (

//         <div className='second-price-inputs'>
//           <div>
//             <span>{symbol}</span>
//             <input
//               type='number'
//               value={selectorData.min}
//               onChange={(e) =>
//                 handleChange(e, (value) => {
//                   setSelectorData({ ...selectorData, min: value });
//                 })
//               }
//             />
//           </div>
//           <div>
//             <span>{symbol}</span>
//             <input
//               type='number'
//               value={selectorData.max}
//               onChange={(e) =>
//                 handleChange(e, (value) => {
//                   setSelectorData({ ...selectorData, max: value });
//                 })
//               }
//             />
//           </div>
//         </div>
//       ) : (
//         ''
//       )}
//     </div>
//   );
// };

const PriceSelector = ({
  min,
  max,
  onChange,
  inputsTop = true,
}) => {
  const [selectorData, setSelectorData] = useState({ min, max });

  const handleChange = (event, setState) => {
    const value = Math.max(min, Math.min(max, Number(event.target.value)));
    setState(value);
  };

  const handleRangeSliderChange = (data) => {
    setSelectorData(data);
    onChange(data);  // Pass values to the parent component
  };

  return (
    <div className='price-selection'>
      {inputsTop && (
        <div className='price-inputs'>
          <input
            type='number'
            value={selectorData.min}
            onChange={(e) =>
              handleChange(e, (value) => {
                setSelectorData({ ...selectorData, min: value });
              })
            }
          />
          <div className='horizontal-line' />
          <input
            type='number'
            value={selectorData.max}
            onChange={(e) =>
              handleChange(e, (value) => {
                setSelectorData({ ...selectorData, max: value });
              })
            }
          />
        </div>
      )}

      <RangeSlider
        min={min}
        max={max}
        minVal={selectorData.min}
        maxVal={selectorData.max}
        onChange={handleRangeSliderChange}
      />

      {!inputsTop && (
        <div className='second-price-inputs'>
          <input
            type='number'
            value={selectorData.min}
            onChange={(e) =>
              handleChange(e, (value) => {
                setSelectorData({ ...selectorData, min: value });
              })
            }
          />
          <input
            type='number'
            value={selectorData.max}
            onChange={(e) =>
              handleChange(e, (value) => {
                setSelectorData({ ...selectorData, max: value });
              })
            }
          />
        </div>
      )}
    </div>
  );
};

export default PriceSelector;
