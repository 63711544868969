import styled from 'styled-components';
import { device } from '../../../../../../assets/style-helpers/breakpoints';

export const TagsStyled = styled.section`
  display: flex;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 67px;
  @media ${device.tablet} {
    margin-bottom: 48px;
    width: auto;
    overflow-y: scroll;
    white-space: nowrap;
  }
  @media ${device.mobile} {
    overflow: hidden;
    flex-direction: column-reverse;
  }
`;

export const Dropdown = styled.div`
  display: flex;
  align-items: center;
  background: var(--main-light-green-2);
  border: 1px solid var(--main-green);
  border-radius: 4px;
  padding: 6px 20px;
  margin-right: 40px;
  color: var(--main-green);
  cursor: pointer;
  @media ${device.mobile} {
    width: 100%;
    justify-content: center;
  }
  img {
    margin-left: 12px;
  }
`;
export const CustomDropdown = styled.div`
  border-radius: 4px;
  padding: 6px 20px;
 
`;
export const Tag = styled.div`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #29263a;
  border-bottom: 1px solid var(--main-green);
  margin-right: 40px;
  cursor: pointer;
`;

export const List = styled.div`
  background: var(--main-white);
  width: 100%;
  min-width: 320px;
  max-width: 320px;
  box-shadow: 0px 4px 60px 0px rgba(62, 73, 59, 0.06);
  padding: 14px;
  max-height: 600px;
  overflow-y: auto;
`;

export const DropdownWrapper = styled.div`
  @media ${device.mobile} {
    width: 100%;
    margin-top: 20px;
  }
`;

export const TagsWrapper = styled.div`
  display: flex;

  flex-wrap: nowrap;
  gap: 16px;
`;

export const TagsContainer = styled.div`
  overflow-y: auto;
  max-width: 100%;
  @media ${device.mobile} {
    padding-bottom: 12px;
  }
  //&::-webkit-scrollbar {
  //  width: 0px;
  //  background: transparent;
  //  height: 0px;
  //}
`;
