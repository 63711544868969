import {
  Section,
  SectionText,
  SectionTitle
} from '../../add-new-product-single/add-new-product-single-styled';
import styled from 'styled-components';

import imgPlaceholder from 'assets/icons/img-placeholder.svg';
import { GlobalButton } from 'components/global-button/global-button';
import { SvgIcon } from 'components/svg-icon/svg-icon';
import { device } from 'assets/style-helpers/breakpoints';
import { useState } from 'react';
import { updateCoverImage, updateLogo } from '../../../../../redux/slices/businessSettingsSlice';
import { useDispatch } from 'react-redux';

const UploadImgSection = styled.div`
  display: flex;
  @media ${device.mobile} {
    flex-direction: column;
    button {
      width: 100%;
    }
  }
`;

const ImgWrapper = styled.div`
  height: 150px;
  width: 100%;
  border-radius: 12px;
  border: 1px solid var(--divider);
  display: flex;
  align-items: center;
  justify-content: center;
  .img-placeholder {
    width: 70px;
    height:70px
    border-radius: 12px;
  }
    .coverImage{
    width: 100%;
    height:100%;
    border-radius: 12px;

    }
`;

const BusinessLogoWrapper = styled.div`
  flex-basis: 150px;
  margin-right: 24px;
  @media ${device.mobile} {
    max-width: 100%;
    flex-basis: auto;
    margin-right: 0;
    margin-bottom: 16px;
    ${ImgWrapper} {
      max-width: 150px;
    }
  }
`;

const CoverWrapper = styled.div`
  flex-basis: calc(100% - 150px - 24px);
  @media ${device.laptop} {
    max-width: 460px;
  }
  @media ${device.mobile} {
    max-width: 100%;
    flex-basis: auto;
  }
`;

const UploadImgSectionTitle = styled.div`
  color: var(--main-gray-3);
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  margin-bottom: 10px;
`;

const UploadBannerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
`;

export const StoreFrontSettingsSection = () => {
  const [logoImage, setLogoImage] = useState(null);
  const [coverImage, setCoverImage] = useState(null);
  const dispatch = useDispatch();

  const handleImageUpload = (e, type) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      const imageDataUrl = reader.result;
      reader.onloadend = () => {
        if (type === 'logo') {
          setLogoImage(reader.result);
          dispatch(updateLogo(reader.result)); // Set the logo image
        } else {
          setCoverImage(reader.result);
          dispatch(updateCoverImage(reader.result)); // Set the cover image
        }

      };
      reader.readAsDataURL(file);
    }
  };



  return (
    <Section>
      <SectionTitle>Store Front Settings</SectionTitle>
      <SectionText>Manage how buyers will see your storefront</SectionText>
      <UploadImgSection>
        <BusinessLogoWrapper>
          <UploadImgSectionTitle>business logo</UploadImgSectionTitle>
          <ImgWrapper>
            {logoImage ? <img src={logoImage || imgPlaceholder} alt="Cover" className="coverImage" /> :
              <img src={logoImage || imgPlaceholder} alt="Cover" className="img-placeholder" />}
          </ImgWrapper>
          <UploadBannerWrapper>
            <GlobalButton type={'orange-secondary'} onClick={() => document.getElementById('logo-upload').click()}>

              <input
                id="logo-upload"
                type="file"
                accept="image/*"
                style={{ display: 'none' }} // Hide file input
                onChange={(e) => handleImageUpload(e, 'logo')} // Handle logo file change
              />
              <SvgIcon name={'upload'} />
              Upload
            </GlobalButton>
          </UploadBannerWrapper>
        </BusinessLogoWrapper>
        <CoverWrapper>
          <UploadImgSectionTitle>Store front cover image</UploadImgSectionTitle>

          <ImgWrapper style= {{ 
             backgroundImage: "url(" + coverImage + ")",
             backgroundSize: "cover", 
             backgroundPosition: "center center", 
             backgroundRepeat: "no-repeat", 
           }}>
          </ImgWrapper>
          <UploadBannerWrapper>
            <GlobalButton
              type={'orange-secondary'}
              onClick={() => document.getElementById('cover-upload').click()} // Trigger file input for cover
            >
              <SvgIcon name={'upload'} />
              Upload
            </GlobalButton>
            <input
              id="cover-upload"
              type="file"
              accept="image/*"
              style={{ display: 'none' }} // Hide file input
              onChange={(e) => handleImageUpload(e, 'cover')} // Handle cover file change
            />

          </UploadBannerWrapper>
        </CoverWrapper>
      </UploadImgSection>
    </Section>
  );
};
