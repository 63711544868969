
import styled, { css } from 'styled-components';
export const TableWrapper = styled.div`
  @media (max-width: 768px) {
    overflow-x: auto;
    margin-right: -15px;
    matgin-top:15px;
  }

  table {
    width: 100%;  /* Full width */
    max-width: 1400px;  /* Increased max-width for a wider table */
    margin: 0 auto;  /* Center the table */
    border-spacing: 0;
    border-collapse: collapse;
    border: 1px solid #ddd;
    border-radius: 8px;
  
  }
`;

export const Styles = styled.div`
  table {
    thead {
      background-color: #f4f4f4;
      font-weight: bold;
      text-align: left;
      th {
        padding: 12px 16px;  
        font-size: 14px;  
        color: #333;
        text-align: center; 
      }
    }

    tbody {
      tr {
        &:hover {
          background-color: #f9f9f9;
        }
      }

      td {
        padding: 8px 12px;  
        font-size: 14px;
        color: #555;
        text-align: center; 
      }
    }

    td input {
      padding: 4px 8px;
      border: 1px solid #ccc;
      border-radius: 4px;
      font-size: 14px;
      width: 90%;  
    }
  
    
    th:nth-child(1),
    td:nth-child(1) {
      width: 15%; 
    }

    th:nth-child(2),
    td:nth-child(2) {
      width: 35%; 
    }

    th:nth-child(3),
    td:nth-child(3) {
      width: 15%;
    }

    th:nth-child(4),
    td:nth-child(4) {
      width: 20%; 
    }

    th:nth-child(5),
    td:nth-child(5) {
      width: 15%; 
    }
  }
`;

export const Button = styled.button`
  padding: 6px 12px;
  background-color: #F87934;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  
  &:hover {
    background-color: #D56C2A;
  }
`;
