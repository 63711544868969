import {
  CheckboxWrapper,
  InputGroupWrapper,
  Section,
  SectionSubtitle,
  SectionTitle,
  Subtext
} from '../../add-new-product-single/add-new-product-single-styled';
import { InputGroup } from 'components/input-group/input-group';
import { GlobalInputSelect } from 'components/global-input-select/global-input-select';
import { Checkboxes, MapWrapper } from '../business-information-styled';
import Checkbox from 'components/checkbox/checkbox';
import { GlobalInput } from 'components/global-input/global-input';
import { GlobalSelect } from 'components/global-select/global-select';

import { AddressSelectWrapper } from 'components/global-select/addressSelect';
import { Link } from 'react-router-dom';
import map from '../img/map.jpg';
import { updatePhoneName, updateWhatsApp, updateEmail, updateAddressField, updateWebsite } from "../../../../../redux/slices/businessSettingsSlice";
import { useSelector, useDispatch } from 'react-redux';
import { useState } from 'react';

export const ContactInformationSection = ({ }) => {
  const dispatch = useDispatch();
  const { whatsApp, phone, email, address } = useSelector(
    (state) => state.businessSettingsReducer
  );

  const selectOptions = [
    { label: "Harare", value: "harare" },
    { label: "Bulawayo", value: "bulawayo" },
    { label: "Manicaland", value: "manicaland" },
    { label: "Mashonaland Central", value: "mashonaland-central" },
    { label: "Mashonaland East", value: "mashonaland-east" },
    { label: "Mashonaland West", value: "mashonaland-west" },
    { label: "Masvingo", value: "masvingo" },
    { label: "Matabeleland North", value: "matebeleland-north" },
    { label: "Matabeleland South", value: "matebeleland-north" },
    { label: "Midlands", value: "midlands" },

  ];
  const selectCountry = [
    { label: "Zimbabwe", value: "zimbabwe" },
  ];
  const [useSameNumberForPhoneCalls, setUseSameNumberForPhoneCalls] = useState(false);


  const handleWhatsAppChange = (event) => {
    const { value } = event.target;
    dispatch(updateWhatsApp(value));
    if (useSameNumberForPhoneCalls) {
      dispatch(updatePhoneName(value));
    }

  };

  const handleUseSameNumberChange = () => {
    setUseSameNumberForPhoneCalls(!useSameNumberForPhoneCalls);
    if (!useSameNumberForPhoneCalls) {
      dispatch(updatePhoneName(whatsApp));
    } else {
      dispatch(updatePhoneName(''));
    }
  }
  const handlePhoneNumberChangee = (event) => {
    const { value } = event.target;
    if (!useSameNumberForPhoneCalls) {
      dispatch(updatePhoneName(value));
    }
  };

  const handleEmailChange = (event) => {
    const email = event.target.value;
    dispatch(updateEmail(email));
  };
  const handleAddressChange = (field) => (event) => {
    const value = event.target.value;
    dispatch(updateAddressField({ field, value }));
  };
  const handleAddressSelect = (field) => (value) => {

    dispatch(updateAddressField({ field, value }));
  };

  return (
    <Section>
      <SectionTitle>Contact Information</SectionTitle>
      <InputGroupWrapper>
        <InputGroup label={'Business whatsapp number'}>
          <GlobalInputSelect placeholder={'Your WhatsApp Number'}
            onChange={handleWhatsAppChange}
            value={whatsApp} name="whatsApp" type='tel' />
        </InputGroup>
        <Subtext>
          Add a WhatsApp to allow your customer to contact you via WhatsApp receive notifications
        </Subtext>
        <Checkboxes>
          <CheckboxWrapper>
            <Checkbox
              label={'Use the same number for phone calls'}
              checked={useSameNumberForPhoneCalls}
              onChange={handleUseSameNumberChange} />
          </CheckboxWrapper>
          <CheckboxWrapper>
            <Checkbox label={'Use this number for notifications'} />
          </CheckboxWrapper>
        </Checkboxes>
      </InputGroupWrapper>
      <InputGroupWrapper>
        {useSameNumberForPhoneCalls ? '' : <InputGroup label={'Phone number'}>
          <GlobalInputSelect placeholder={'Your Phone Number'}
            onChange={handlePhoneNumberChangee}
            value={useSameNumberForPhoneCalls ? whatsApp : phone}
            disabled={useSameNumberForPhoneCalls}

          />
        </InputGroup>}

      </InputGroupWrapper>
      <InputGroupWrapper>
        <InputGroup label={'Business email address'}>
          <GlobalInput placeholder={'Enter your business email'} onChange={handleEmailChange} value={email} name="email" type="email" />
        </InputGroup>
      </InputGroupWrapper>
      <InputGroupWrapper>
        <Checkbox label={'Use this email for notifications'} />
      </InputGroupWrapper>

      <SectionSubtitle>Shop Address</SectionSubtitle>
      <InputGroupWrapper>
        <InputGroup label={'shop / office number'}>
          <GlobalInput placeholder={'e.g. Shop 3, 4th Floor, Galaxy Mall'} value={address.shopAddress} onChange={handleAddressChange('shopAddress')} />
        </InputGroup>
      </InputGroupWrapper>
      <InputGroupWrapper>
        <InputGroup label={'street address'}>
          <GlobalInput placeholder={'e.g. 53 Jason Moyo Ave'} value={address.streetAddress} onChange={handleAddressChange('streetAddress')} />
        </InputGroup>
      </InputGroupWrapper>
      <InputGroupWrapper>
        <InputGroup label={'suburb'}>
          <GlobalInput placeholder={'e.g. Harare City Centre'} value={address.suburb} onChange={handleAddressChange('suburb')} />
        </InputGroup>
      </InputGroupWrapper>

      <InputGroupWrapper>
        <InputGroup label={'city'}>
          <GlobalInput placeholder={'e.g.Bulawayo'} value={address.city} onChange={handleAddressChange('city')} />

        </InputGroup>
      </InputGroupWrapper>


      <InputGroupWrapper>
        <InputGroup label={'Province'}>
          <GlobalSelect
            options={selectOptions}
            onChange={(selectedOption) => handleAddressSelect('province')(selectedOption.label)}
            value={address.province || ''}
            placeholder={address.province || 'Select province'}
          />
        </InputGroup>
      </InputGroupWrapper>

      <InputGroupWrapper>
        <InputGroup label={'country'}>
          <GlobalSelect
            options={selectCountry}
            onChange={(selectCountry) => handleAddressSelect('country')(selectCountry.label)}
            value={address.country || ''}
            placeholder={address.country || 'Select country'}
          />
          {/* <AddressSelectWrapper
            field="country"
            options={selectOptions}
            onChange={handleAddressChange('country')}
            value={address.province || ''}
          /> */}
        </InputGroup>
      </InputGroupWrapper>
      <InputGroupWrapper>
        <span>
          Does your business have have shops in multiple locations?{' '}
          <Link to={'#'}> Manage Shop Locations</Link>
        </span>
      </InputGroupWrapper>
      <SectionSubtitle>Map Location</SectionSubtitle>
      <MapWrapper>
        <img src={map} alt='' />
      </MapWrapper>
    </Section>
  );
};
