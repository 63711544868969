import {
  CloseFilterBtn,
  Container,
  FilterGroup,
  FilterGroupTitle,
  FilterTitle,
  Label,
  MobileBg,
  MobileHeader,
  Section,
  ShowMoreBtn
} from './global-filter-styled';
import filter from '../../assets/filter.svg';
import React, { useEffect, useRef, useState } from 'react';
import { Category } from './comonents/category/category';
import PriceSelector from '../price-selector/price-selector';
import { GlobalCheckbox } from '../global-checkbox/global-checkbox';
import { FiltersAdSpace } from '../../pages/categories-real-estate/components/filters/filters-styled';
import { FilterWidget } from '../filter-widget/filter-widget';
import { ModalWrapper } from '../modal-wrapper/modal-wrapper';
import useOutsideClick from '../../hooks/useOutsideClick';

// const categories = [
//   {
//     title: 'Category',
//     qty: 100,
//     subcategory: [
//       { title: 'Subcategory', qty: 50 },
//       { title: 'Subcategory-2', qty: 50 }
//     ]
//   },
//   {
//     title: 'Category 2',
//     qty: 100,
//     subcategory: [
//       { title: 'Subcategory', qty: 50 },
//       { title: 'Subcategory-2', qty: 50 }
//     ]
//   },
//   {
//     title: 'Category 3',
//     qty: 100,
//     subcategory: [
//       { title: 'Subcategory', qty: 50 },
//       { title: 'Subcategory-2', qty: 50 }
//     ]
//   },
//   {
//     title: 'Category 4',
//     qty: 100,
//     subcategory: [
//       { title: 'Subcategory', qty: 50 },
//       { title: 'Subcategory-2', qty: 50 }
//     ]
//   },
//   {
//     title: 'Category 5',
//     qty: 100,
//     subcategory: [
//       { title: 'Subcategory', qty: 50 },
//       { title: 'Subcategory-2', qty: 50 }
//     ]
//   },
//   {
//     title: 'Category 6',
//     qty: 100,
//     subcategory: [
//       { title: 'Subcategory', qty: 50 },
//       { title: 'Subcategory-2', qty: 50 }
//     ]
//   },
//   {
//     title: 'Category 7',
//     qty: 100,
//     subcategory: [
//       { title: 'Subcategory', qty: 50 },
//       { title: 'Subcategory-2', qty: 50 }
//     ]
//   },
//   {
//     title: 'Category 8',
//     qty: 100,
//     subcategory: [
//       { title: 'Subcategory', qty: 50 },
//       { title: 'Subcategory-2', qty: 50 }
//     ]
//   }
// ];

const checkboxes = ['Filter one', 'Filter two', 'Filter three'];

export const GlobalFilter = ({
  isMobileFilterVisible,
  onCloseFilterBtnClick,
  isAdSpace = true,
  business,
  categories,
  setSelectedCategory,
  setPriceRange,
}) => {

  const [isFilterWidgetVisible, setIsFilterWidgetVisible] = useState(false);


  return (
    <>
      <MobileBg
        isMobileFilterVisible={isMobileFilterVisible}
        onClick={() => {
          onCloseFilterBtnClick();
        }}
      />
      <Container isMobileFilterVisible={isMobileFilterVisible}>
        <MobileHeader>
          <span>
            <svg
              width='20'
              height='20'
              viewBox='0 0 20 20'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <rect
                width='11.6111'
                height='1.85714'
                rx='0.928571'
                transform='matrix(-1 0 0 1 19 12.2852)'
                fill='#29263A'
              />
              <path
                d='M2.69966 11.2456C1.62653 12.3335 1.62653 14.0973 2.69966 15.1852C3.77278 16.2731 5.51266 16.2731 6.58578 15.1852C7.65891 14.0973 7.65891 12.3335 6.58578 11.2456C5.51266 10.1577 3.77278 10.1577 2.69966 11.2456Z'
                stroke='#29263A'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <rect
                x='1'
                y='4.85742'
                width='11.6111'
                height='1.85714'
                rx='0.928571'
                fill='#29263A'
              />
              <path
                d='M17.3003 3.81592C18.3735 4.90381 18.3735 6.66762 17.3003 7.75551C16.2272 8.8434 14.4873 8.8434 13.4142 7.75551C12.3411 6.66762 12.3411 4.90381 13.4142 3.81592C14.4873 2.72803 16.2272 2.72803 17.3003 3.81592Z'
                stroke='#29263A'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
            Filters
          </span>
          <CloseFilterBtn onClick={onCloseFilterBtnClick}>
            <svg
              width='28'
              height='28'
              viewBox='0 0 28 28'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M21 7L7 21M7 7L21 21'
                stroke='#29263A'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </CloseFilterBtn>
        </MobileHeader>

        <Section>
          <FilterTitle>Category title</FilterTitle>


          {categories.map((item) => (
            <Category
              title={item.name}
              qty={item.products.length || 'N/A'}
              key={item.title}
              onClick={() => setSelectedCategory(item.name)}
            />

          ))}
        </Section>


        <Section>
          <FilterTitle>
            Filters
            <img src={filter} alt='' />
          </FilterTitle>
          <FilterGroup>
            <FilterGroupTitle>Range type</FilterGroupTitle>
            <PriceSelector
              min={0}
              max={100000}
              onChange={({ min, max }) => setPriceRange({ min, max })}
              inputsTop={false}
            />
          </FilterGroup>
          {/* TODO Filter products by products categories */}
          <FilterGroup>
            <FilterGroupTitle>Sale type</FilterGroupTitle>
            {checkboxes.map((item) => (
              <GlobalCheckbox isChecked key={item}>
                <Label>{item}</Label>
              </GlobalCheckbox>
            ))}
          </FilterGroup>
          <FilterGroup>
            <FilterGroupTitle>Availability</FilterGroupTitle>
            {checkboxes.map((item) => (
              <GlobalCheckbox isChecked key={item}>
                <Label>{item}</Label>
              </GlobalCheckbox>
            ))}
            <ShowMoreBtn
              onClick={() => {
                setIsFilterWidgetVisible(true);
              }}
            >
              Show more
            </ShowMoreBtn>
          </FilterGroup>
          <FilterGroup>
            <FilterGroupTitle>Filter type</FilterGroupTitle>
            {checkboxes.map((item) => (
              <GlobalCheckbox isChecked key={item}>
                <Label>{item}</Label>
              </GlobalCheckbox>
            ))}
            {/* <ShowMoreBtn
              onClick={() => {
                setIsFilterWidgetVisible(true);
              }}
            >
              Show more
            </ShowMoreBtn> */}
          </FilterGroup>
        </Section>
        {isAdSpace && <FiltersAdSpace />}
      </Container>
      <FilterWidget
        onClose={() => {
          setIsFilterWidgetVisible(false);
        }}
        isVisible={isFilterWidgetVisible}
        setIsVisible={setIsFilterWidgetVisible}
      />
    </>
  );
};
