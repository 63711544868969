import styled, { css } from 'styled-components';
import { device } from '../../assets/style-helpers/breakpoints';
import { Link } from 'react-router-dom';
import { LatoFont } from '../../assets/style-helpers/style-helpers';

const card = css`
  border-radius: 12px;
  overflow: hidden;
  background: var(--gray-bg);
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 80px;
`;

export const Banner = styled.div`
  ${card};
  flex-basis: 100%;
  margin-bottom: 24px;
  height: 400px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media ${device.tablet} {
    margin-bottom: 20px;
    height: 250px;
  }
  @media ${device.mobile} {
    height: 320px;
  }
`;

export const MainInfo = styled.div`
  ${card};
  margin-right: 32px;
  flex-basis: calc(100% - 505px - 32px);
  padding: 20px 20px 34px;
  @media ${device.laptop} {
    flex-basis: calc(100% - 400px - 32px);
  }
  @media ${device.tablet} {
    flex-basis: calc(50% - 10px);
    margin-right: 20px;
    padding: 12px 12px 24px;
  }
  @media ${device.mobile} {
    flex-basis: 100%;
    margin-bottom: 24px;
    margin-right: 0;
  }
`;

export const MainInfoTop = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--main-gray-2);
  @media ${device.tablet} {
    flex-direction: column;
    > button {
      width: 100%;
      margin-top: 12px;
    }
  }
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
`;

export const InfoLogo = styled.div`
  margin-right: 20px;
  border-radius: 6px;
  width: 90px;
  height: 90px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const InfoContent = styled.div``;

export const Title = styled.div`
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 4px;
  @media ${device.tablet} {
    font-size: 18px;
    margin-bottom: 0;
  }
`;

export const Subtitle = styled.div`
  color: var(--main-subtext);
  font-family: 'Lato', sans-serif;
  line-height: 24px;
  margin-bottom: 6px;
`;

export const RatingWrapper = styled.div`
  display: flex;
  align-items: center;
  line-height: 24px;
  font-size: 12px;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  @media ${device.tablet} {
    flex-wrap: wrap;
    > div:first-child {
      flex-basis: 100%;
      margin-bottom: 2px;
    }
  }
`;

export const Reviews = styled.div`
  color: var(--main-green);
  padding-right: 6px;
  border-right: 1px solid #d7e0fd;
  margin-right: 6px;
  margin-left: 6px;
  @media ${device.tablet} {
    margin-left: 0;
  }
`;

export const Sold = styled.div``;

export const Address = styled.div`
  ${card};
  flex-basis: 505px;
  @media ${device.laptop} {
    flex-basis: 400px;
  }
  @media ${device.tablet} {
    flex-basis: calc(50% - 10px);
  }
  @media ${device.mobile} {
    flex-basis: 100%;
  }
`;

export const MainInfoDescription = styled.div`
  padding-top: 24px;
  display: flex;
  justify-content: space-between;
  @media ${device.tablet} {
    flex-direction: column;
  }
`;

export const AboutUs = styled.div`
  max-width: 604px;
  @media ${device.laptop} {
    max-width: 340px;
  }
  @media ${device.tablet} {
    padding-bottom: 16px;
    border-bottom: 1px solid var(--main-gray-2);
    margin-bottom: 22px;
  }
`;

export const AboutUsTitle = styled.div`
  font-size: 17px;
  line-height: 24px;
  margin-bottom: 8px;
`;

export const AboutUsDescription = styled.div`
  font-family: 'Lato', sans-serif;
`;

export const OpenHours = styled.div`
  max-width: 320px;
  flex-basis: 320px;
  @media ${device.tablet} {
    flex-basis: auto;
  }
`;

export const OpenHoursTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const OpenHoursTitle = styled.div`
  font-size: 17px;
  line-height: 24px;
  margin-bottom: 8px;
`;

export const OpenHoursStatus = styled.div`
  background: rgba(124, 204, 103, 0.15);
  border-radius: 4px;
  font-size: 10px;
  font-family: 'Lato', sans-serif;
  line-height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px 0 18px;
  height: 20px;
  font-weight: 700;
  color: var(--main-green);
  position: relative;
  &::before {
    content: '';
    position: absolute;
    left: 8px;
    top: 8px;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: var(--main-green);
  }
`;

export const OpenHoursList = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 88px;
`;

export const OpenHoursItem = styled.div`
  display: flex;
  align-items: center;
  span {
    font-size: 14px;
    line-height: 22px;
    font-weight: 700;
    font-family: 'Lato', sans-serif;
    &:first-child {
      width: 32px;
    }
    &:last-child {
      font-weight: 400;
      margin-left: 20px;
      color: #5a5670;
    }
  }
`;

export const Map = styled.div`
  width: 100%;
  height: 200px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media ${device.tablet} {
    height: 326px;
  }
`;

export const AddressMain = styled.div`
  padding: 20px 20px 24px;
`;

export const AddressTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 17px;
  line-height: 24px;
  margin-bottom: 8px;
  img {
    width: 17px;
    height: 20px;
    margin-right: 12px;
  }
`;

export const AddressText = styled.div`
  font-family: 'Lato', sans-serif;
  margin-bottom: 16px;
`;

export const SocialLinks = styled.div`
  display: flex;
  align-items: center;
  a {
    border-radius: 50%;
    filter: drop-shadow(0px 4px 10px rgba(54, 100, 246, 0.3));
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--main-white);
    background: var(--main-green);
    &:hover {
      color: var(--main-white);
    }
  }

  a + a {
    margin-left: 14px;
  }
`;

export const Social = styled.div`
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid var(--main-gray-2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media ${device.tablet} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const SocialTitle = styled.div`
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  @media ${device.tablet} {
    margin-bottom: 8px;
  }
`;

export const SocialInfoLinks = styled.div`
  display: flex;
`;

export const SocialItemLink = styled(Link)`
  border-radius: 50%;
  width: 36px;
  height: 36px;
  background: rgba(56, 134, 56, 0.09);
  /* new */

  box-shadow: 0px 4px 60px rgba(70, 91, 131, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;

  & + & {
    margin-left: 16px;
  }
`;

export const FollowBtnWrapper = styled.div`
  > button {
    min-width: 132px;
  }
`;

export const Followers = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 200% */
  ${LatoFont};
  svg {
    margin-right: 6px;
  }
`;
