import React from 'react';

import {
    Badge,
    Buttons,
    BuyBtn,
    Container,
    Content,
    FavBtn4,
    ImgWrapper,
    SubTitle,
    Main,
    MainPrice,
    RateStarsDesktop,
    OldPrice,
    MainBox,
    Price,
    LabelNotAvailable,
    ContactsBox,
    Title,
    Label,
    ZWLPrice,
    LabelInStock,
    LabelOutStock
} from './p-card-styled';
import { SvgIcon } from '../svg-icon/svg-icon';
import { RateStars } from '../rate-stars/rate-stars';
import { ApproveSeller } from '../approve-seller/approve-seller';

export const PCardStoreFront = ({ product, handleFavBtnClick, isFav, business }) => {
    return (
        <Container>
            <ImgWrapper>
                <ApproveSeller />
                <img src={product.image.url} alt={product.image.altText} />
                {product.availability.status ? (
                    <LabelInStock>Stock</LabelInStock>
                ) : (
                    <LabelOutStock>Out stock</LabelOutStock>
                )}
                {/* {product.delivery ? (
          <Label>Delivery Available</Label>
        ) : (
          <LabelNotAvailable>Delivery not available</LabelNotAvailable>
        )} */}

                <FavBtn4 onClick={handleFavBtnClick} isFav={isFav}>
                    <SvgIcon name='heart-2' />
                </FavBtn4>
            </ImgWrapper>
            <Main>
                <MainBox>
                    <RateStarsDesktop>
                        {product.rating && (
                            <RateStars rate={product.rating} reviews={product.reviews} color={'#388638'} />
                        )}
                    </RateStarsDesktop>
                    <ContactsBox>
                        <a
                            href={`https://wa.me/${business?.whatsApp}?text=Hi,%20I%20saw%20your%20shop%20on%20https://everythingzimbabwean.com%20and%20I'd%20like%20to%20ask%20about%20${product.name}`}
                            type="tel" target='_blank'
                        >
                            <SvgIcon name="whatsup" />
                        </a>

                        <a href={`mailto:${business?.email}`} target='_blank'>
                            <SvgIcon name={'email'} />
                        </a>
                        <a href={`tel:${business?.phone}`} target='_blank'>
                            <SvgIcon name={'phone'} />
                        </a>
                    </ContactsBox>
                </MainBox>
                <Title>{product.title}</Title>
                {<SubTitle>{[business?.address.shopAddress, business?.address.streetAddress].join(', ')}</SubTitle>}
                <Content>
                    <Price>
                        {(product.price.sellingPrice.find(price => price.currency === "USD").amount).length ?
                            <OldPrice>${product.price.sellingPrice.find(price => price.currency === "USD")?.amount || ''}</OldPrice> : ''
                        }

                        <MainPrice>USD {product.price.normalPrice.find(price => price.currency === "USD")?.amount || ''}</MainPrice>

                        {/* {(product.price.normalPrice.find(price => price.currency === "ZAR").amount).length ?
                            <ZWLPrice>ZWL{product.price.normalPrice.find(price => price.currency === "ZAR")?.amount || ''}</ZWLPrice> : ''
                        } */}
                        {/* <ZWLPrice>ZWL {product.price.normalPrice.find(price => price.currency === "ZAR")?.amount || ''}</ZWLPrice> */}
                    </Price>
                    <Buttons>
                        <BuyBtn>
                            <SvgIcon name='cart' />
                        </BuyBtn>
                    </Buttons>
                </Content>
                {product.badge && <Badge>{product.badge}</Badge>}
            </Main>
        </Container>
    );
};
