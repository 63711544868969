import { useDispatch, useSelector } from 'react-redux';
import { updateOpeningTimes } from '../../../../../redux/slices/businessSettingsSlice';
import { Section, SectionTitle } from '../../add-new-product-single/add-new-product-single-styled';
import { Radio } from '../../../../../components/radio/radio';
import { useState, useEffect } from 'react';
import {
  GreenText,
  HourInput,
  RadioWrapper,
  WorkingHoursCell,
  WorkingHoursForm,
  WorkingHoursRow,
  TimeInput
} from '../business-information-styled';
import Checkbox from '../../../../../components/checkbox/checkbox';

export const OpeningTimesSection = () => {
  const dispatch = useDispatch();
  const openingTimes = useSelector(state => state.businessSettingsReducer.openingTimes);

  const [activeRadio, setActiveRadio] = useState('always');


  useEffect(() => {
    if (activeRadio === 'no') {
      updateAllDaysToClose();
    } else if (activeRadio === 'always') {
      updateAllTimesToOpen();
    }
  }, [activeRadio]);


  const handleTimeChange = (day, type, value) => {
    dispatch(updateOpeningTimes({ day, type, value }));
  };


  const handleCloseForDayChange = (day) => {
    dispatch(updateOpeningTimes({ day, type: 'disabled', value: !openingTimes[day].disabled }));
  };


  const updateAllDaysToClose = () => {
    Object.keys(openingTimes).forEach(day => {
      if (!openingTimes[day].disabled) {
        dispatch(updateOpeningTimes({ day, type: 'disabled', value: true }));
      }
    });
  };


  const updateAllTimesToOpen = () => {
    Object.keys(openingTimes).forEach(day => {
      if (openingTimes[day].disabled) {
        dispatch(updateOpeningTimes({ day, type: 'disabled', value: false }));
      }
    });
  };

  return (
    <Section>
      <SectionTitle>Opening Times</SectionTitle>
      {/* TODO Fix for tablet view */}
      {/*  
      TODO      
      <RadioWrapper>
        <Radio
          id={'always'}
          isChecked={activeRadio === 'always'}
          title={'Always open'}
          name={'open_hours'}
          value={'always'}
          cb={() => setActiveRadio('always')}
        />
        <Radio
          id={'select'}
          isChecked={activeRadio === 'select'}
          title={'Open on selected hours'}
          name={'open_hours'}
          value={'select'}
          cb={() => setActiveRadio('select')}
        />
        <Radio
          id={'no'}
          name={'open_hours'}
          isChecked={activeRadio === 'no'}
          title={'No opening hours'}
          value={'no'}
          cb={() => setActiveRadio('no')}
        />
      </RadioWrapper> */}
      <WorkingHoursForm>
        <WorkingHoursRow>
          <WorkingHoursCell></WorkingHoursCell>
          <WorkingHoursCell>
            <GreenText>Opening</GreenText>
          </WorkingHoursCell>
          <WorkingHoursCell>
            <GreenText>Closing</GreenText>
          </WorkingHoursCell>
          <WorkingHoursCell></WorkingHoursCell>
        </WorkingHoursRow>

        {Object.keys(openingTimes).map((day, i) => (
          <WorkingHoursRow key={i}>
            <WorkingHoursCell>{day.charAt(0).toUpperCase() + day.slice(1)}</WorkingHoursCell>
            <WorkingHoursCell>
              <TimeInput
                type="time"
                disabled={openingTimes[day].disabled}
                value={openingTimes[day].open || '09:00'}
                onChange={(e) => handleTimeChange(day, 'open', e.target.value)}
              />
            </WorkingHoursCell>
            <WorkingHoursCell>
              <TimeInput
                type="time"
                disabled={openingTimes[day].disabled}
                value={openingTimes[day].close || '17:00'}
                onChange={(e) => handleTimeChange(day, 'close', e.target.value)}
              />
            </WorkingHoursCell>
            <WorkingHoursCell>
              <Checkbox
                label={'Close for the day'}
                checked={openingTimes[day].disabled}
                onChange={() => handleCloseForDayChange(day)}
              />
            </WorkingHoursCell>
          </WorkingHoursRow>
        ))}
      </WorkingHoursForm>
    </Section>
  );
};
