import React from 'react';
import styled from 'styled-components';
import './checkbox.scss';

import checkmark from '../../assets/checkmark.svg';
import { useState } from 'react';

const DEFAULT_COLOR_CHECKED = '#E7F0E7';
const DEFAULT_COLOR_UNCHECKED = '#FFFFFF';
export const Input = styled.div`
  input[type='checkbox'] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    appearance: none;
    /* For iOS < 15 to remove gradient background */
    background: ${(props) => (props.color ? props.color : DEFAULT_COLOR_UNCHECKED)};
    /* Not removed via appearance */
    margin: 0;
    width: 20px;
    height: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    border: 1px solid #e2e5eb;
    box-shadow: none;
    padding: 6px;
  }

  input[type='checkbox']::before {
    content: '';
    width: 0.65em;
    height: 0.65em;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
  }

  input[type='checkbox']:checked {
    background: ${(props) => (props.color ? props.color : DEFAULT_COLOR_CHECKED)};
    border: ${(props) => (props.color ? '1px solid #E0EBFF' : '1px solid transparent')};
    box-shadow: none;
  }
`;

const Checkbox = ({
  id,
  wrapperClassName,
  label,
  labelNumber,
  checked,
  onChange,
  withoutCheckmark = false,
  color
}) => {
  const [isChecked, setIsChecked] = useState(checked);

  const handleCheckChange = (e) => {
    setIsChecked(e.target.checked);
    if (onChange) onChange(e); // Allow parent to handle change as well
  };
  return (
    <div className={`${wrapperClassName} checkbox-global`}>
      <Input color={color}>
        <input id={`checkbox-${id}`} type='checkbox' checked={isChecked} onChange={handleCheckChange} />
      </Input>
      {!withoutCheckmark ? (
        <img
          className={`checkmark ${isChecked ? 'show__checkmark' : 'hide__checkmark'}`}
          src={checkmark}
          alt=''
        />
      ) : (
        ''
      )}
      <label
        htmlFor={'checkbox'}
        className='label'
        style={{ color: color && isChecked ? '#3664F6' : '#29263A' }}
      >
        {label && label}
        {labelNumber && <span>{labelNumber}</span>}
      </label>
    </div>
  );
};

export default Checkbox;
